import {
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Avatar,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import BusinessInfoInput from "./BusinessInfoInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const BusinessInfo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("businessInfo");

  return (
    <>
      <DialogContent>
        <Box display="flex" alignItems="center" marginBottom="10px">
          <Avatar sx={{ bgcolor: "#4AB5D8", marginRight: "10px" }}>
            <FontAwesomeIcon icon={faBriefcase} size="lg" color="#13436D" />
          </Avatar>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            color="secondary"
            sx={{ fontWeight: 600, margin: 0 }}
          >
            {t("titleBusiness")}
          </Typography>
        </Box>
        <BusinessInfoInput />
      </DialogContent>
      <DialogActions sx={{ p: "0px 24px 16px 24px" }}>
        <Button onClick={() => navigate("/personal-info")}>{t("back")}</Button>
        <Button
          form="submit-form"
          variant="contained"
          color="primary"
          sx={{ color: "white" }}
          type="submit"
        >
          {t("next")}
        </Button>
      </DialogActions>
    </>
  );
};

export default BusinessInfo;
