import { useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { phoneVerificationState } from "../../recoil/phone-verification/PhoneVerificationFormState";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../../components/form-input";
import SnackbarAlert from "../../components/snackbar-alert";
import { DataAPI } from "../../api/data";
import { sessionInfoState } from "../../recoil/session-info/session-info-state";
import { captureException } from "@sentry/react";
import { track } from "../../utils";

type HandleNext = {
  handleNext: () => void;
};

const PhoneInput = ({ handleNext }: HandleNext) => {
  const [phone, setPhone] = useRecoilState(phoneVerificationState);
  const sessionState = useRecoilValue(sessionInfoState);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { t } = useTranslation("phoneVerification");
  const validationSchema = object({
    phone: string()
      .nonempty(t("errorPhoneRequired"))
      .regex(
        /^(\+\d{1,2}\s)?\(?[2-9]\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        t("errorInvalidNumber")
      ),
  });

  type RegisterInput = TypeOf<typeof validationSchema>;
  const methods = useForm<RegisterInput>({
    resolver: zodResolver(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful, phone]);

  const onSubmitHandler: SubmitHandler<RegisterInput> = async (values) => {
    try {
      setLoading(true);
      const response = await DataAPI.sendCode(
        {
          phoneNumber: values?.phone.replace(/\D+/g, "").substring(1),
          countryCode: "1",
          requestType: "sms",
          emailAddress: undefined,
        },
        sessionState.sessionToken || "",
        sessionState.checkoutId || ""
      );
      if (response.data && response.data.isValid) {
        setLoading(false);
        track("Verification code sent");
        handleNext();
        setPhone(values);
      } else {
        setErrorMsg(response.error.response.data.errors[0].message);
        setLoading(false);
      }
    } catch (error) {
      captureException(error);
      setErrorMsg(t("errorRequest"));
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <FormInput
          name="phone"
          value={phone?.phone}
          required
          fullWidth
          label={t("inputLabel")}
          type="text"
          sx={{ my: 2 }}
          autoFocus
        />
        <Box sx={{ position: "relative" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ color: "white" }}
            disabled={loading}
            type="submit"
            endIcon={
              <FontAwesomeIcon icon={faPaperPlane} size="lg" color="white" />
            }
          >
            {t("send")}
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "0%",
                marginTop: "-12px",
                marginLeft: "55px",
              }}
            />
          )}
        </Box>
        <SnackbarAlert errorMsg={errorMsg} />
      </Box>
    </FormProvider>
  );
};

export default PhoneInput;
