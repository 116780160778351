import {
  Box,
  CircularProgress,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataAPI } from "../../api/data";
import { sessionInfoState } from "../../recoil/session-info/session-info-state";
import { useRecoilValue } from "recoil";
import parse from "html-react-parser";
import SnackbarAlert from "../../components/snackbar-alert";
import { captureException } from "@sentry/react";

const Accept = ({ setAgreements }: any) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const theme = useTheme();
  const [agreement, setAgreement] = useState("");
  const { t } = useTranslation("acceptFinal");
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const sessionState = useRecoilValue(sessionInfoState);

  const htmlDecode = (input: string) => {
    const e = document.createElement("div");
    e.innerHTML = input;
    setAgreement(e.innerText);
    return e.innerText;
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await DataAPI.getAgreement(
          { languge: "en" },
          sessionState.sessionToken || "",
          sessionState.checkoutId || ""
        );
        htmlDecode(data.agreement.agreementContent);
        setAgreements(data.requiredSignatures);
        setLoading(false);
      } catch (error) {
        captureException(error);
        setErrorMsg(t("errorGeneral"));
      }
    })();
  }, []);

  return !loading ? (
    <Box>
      <Typography
        variant={desktop ? "body1" : "body2"}
        gutterBottom
        sx={{ marginTop: "10px", textAlign: "center", fontWeight: 600 }}
      >
        {t("accept")}
      </Typography>
      <Typography
        variant={desktop ? "body1" : "body2"}
        gutterBottom
        sx={{
          marginTop: "10px",
          border: "2px solid #4ab5d8",
          borderRadius: "4px",
          p: 1,
          overflowY: "scroll",
          maxHeight: "315px",
        }}
      >
        {parse(agreement)}
      </Typography>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "325px",
      }}
    >
      <CircularProgress />
      <SnackbarAlert errorMsg={errorMsg} />
    </Box>
  );
};

export default Accept;
