import { MakeGetRequest, MakePostRequest } from ".";
import {
  ApplyRequest,
  ApplyResponse,
  AuthorizeTransactionRequest,
  AuthorizeTransactionResponse,
  CheckoutRequest,
  CheckoutResponse,
  EntityListRequest,
  EntityListResponse,
  GetAgreementRequest,
  GetAgreementResponse,
  GetPaymentOptionsRequest,
  GetPaymentOptionsResponse,
  IndustryListRequest,
  IndustryListResponse,
  LookupRequest,
  LookupResponse,
  ProvinceListRequest,
  ProvinceListResponse,
  SendCodeRequest,
  SendCodeResponse,
  SetPaymentOptionRequest,
  SetPaymentOptionResponse,
  SignAgreementRequest,
  SignAgreementResponse,
  TabitServerResponse,
  VerifyCodeRequest,
  VerifyCodeResponse,
} from "./types/tabitTypes";

export const DataAPI: DataApi = {
  getPaymentOptions: async (
    payload: GetPaymentOptionsRequest,
    sessionToken: string,
    checkoutId: string
  ): Promise<TabitServerResponse<GetPaymentOptionsResponse>> => {
    const url = `/getPaymentOptions`;
    return await MakeGetRequest<
      GetPaymentOptionsRequest,
      GetPaymentOptionsResponse
    >(url, true, sessionToken, checkoutId);
  },
  setPaymentOption: async (
    payload: SetPaymentOptionRequest,
    sessionToken: string,
    checkoutId: string
  ): Promise<TabitServerResponse<SetPaymentOptionResponse>> => {
    const url = `/setPaymentOption`;
    return await MakePostRequest<
      SetPaymentOptionRequest,
      SetPaymentOptionResponse
    >(payload, url, true, sessionToken, checkoutId);
  },
  sendCode: async (
    payload: SendCodeRequest,
    sessionToken: string,
    checkoutId: string
  ): Promise<TabitServerResponse<SendCodeResponse>> => {
    const url = `/sendCode`;
    return await MakePostRequest<SendCodeRequest, SendCodeResponse>(
      payload,
      url,
      false,
      sessionToken,
      checkoutId
    );
  },
  verifyCode: async (
    payload: VerifyCodeRequest,
    sessionToken: string,
    checkoutId: string
  ): Promise<TabitServerResponse<VerifyCodeResponse>> => {
    const url = `/verifyCode`;
    return await MakePostRequest<VerifyCodeRequest, VerifyCodeResponse>(
      payload,
      url,
      false,
      sessionToken,
      checkoutId
    );
  },
  checkout: async (
    payload: CheckoutRequest
  ): Promise<TabitServerResponse<CheckoutResponse>> => {
    const url = `/checkout`;
    return await MakePostRequest<CheckoutRequest, CheckoutResponse>(
      payload,
      url,
      false
    );
  },
  lookup: async (
    payload: LookupRequest,
    sessionToken: string,
    checkoutId: string
  ): Promise<TabitServerResponse<LookupResponse>> => {
    const url = `/lookup?phoneNumber=${payload.phoneNumber}`;
    return await MakeGetRequest<LookupRequest, LookupResponse>(
      url,
      true,
      sessionToken,
      checkoutId
    );
  },
  apply: async (
    payload: ApplyRequest,
    sessionToken: string,
    checkoutId: string
  ): Promise<TabitServerResponse<ApplyResponse>> => {
    const url = `/apply`;
    return await MakePostRequest<ApplyRequest, ApplyResponse>(
      payload,
      url,
      false,
      sessionToken,
      checkoutId
    );
  },
  getIndustryList: async (
    payload: IndustryListRequest,
    sessionToken: string,
    checkoutId: string
  ): Promise<TabitServerResponse<IndustryListResponse>> => {
    const url = `/industries?language=${payload.languge}`;
    return await MakeGetRequest<IndustryListRequest, IndustryListResponse>(
      url,
      true,
      sessionToken,
      checkoutId
    );
  },
  getProvinceList: async (
    payload: ProvinceListRequest,
    sessionToken: string,
    checkoutId: string
  ): Promise<TabitServerResponse<ProvinceListResponse>> => {
    const url = `/provinces?language=${payload.languge}`;
    return await MakeGetRequest<ProvinceListRequest, ProvinceListResponse>(
      url,
      true,
      sessionToken,
      checkoutId
    );
  },
  getEntityList: async (
    payload: EntityListRequest,
    sessionToken: string,
    checkoutId: string
  ): Promise<TabitServerResponse<EntityListResponse>> => {
    const url = `/entities?language=${payload.languge}`;
    return await MakeGetRequest<EntityListRequest, EntityListResponse>(
      url,
      true,
      sessionToken,
      checkoutId
    );
  },
  getAgreement: async function (
    payload: GetAgreementRequest,
    sessionToken: string,
    checkoutId: string
  ): Promise<TabitServerResponse<GetAgreementResponse>> {
    const url = `/getAgreement?language=${payload.languge}`;
    return await MakeGetRequest<GetAgreementRequest, GetAgreementResponse>(
      url,
      true,
      sessionToken,
      checkoutId
    );
  },
  signAgreement: async function (
    payload: SignAgreementRequest,
    sessionToken: string,
    checkoutId: string
  ): Promise<TabitServerResponse<SignAgreementResponse>> {
    const url = `/signAgreement`;
    return await MakePostRequest<SignAgreementRequest, SignAgreementResponse>(
      payload,
      url,
      true,
      sessionToken,
      checkoutId
    );
  },
  authorizeTransaction: async function (
    payload: AuthorizeTransactionRequest,
    sessionToken: string,
    checkoutId: string
  ): Promise<TabitServerResponse<AuthorizeTransactionResponse>> {
    const url = `/authorize`;
    return await MakePostRequest<
      AuthorizeTransactionRequest,
      AuthorizeTransactionResponse
    >(payload, url, true, sessionToken, checkoutId);
  },
};

type DataApi = {
  getPaymentOptions: (
    payload: GetPaymentOptionsRequest,
    sessionToken: string,
    checkoutId: string
  ) => Promise<TabitServerResponse<GetPaymentOptionsResponse>>;
  setPaymentOption: (
    payload: SetPaymentOptionRequest,
    sessionToken: string,
    checkoutId: string
  ) => Promise<TabitServerResponse<SetPaymentOptionResponse>>;
  sendCode: (
    payload: SendCodeRequest,
    sessionToken: string,
    checkoutId: string
  ) => Promise<TabitServerResponse<SendCodeResponse>>;
  verifyCode: (
    payload: VerifyCodeRequest,
    sessionToken: string,
    checkoutId: string
  ) => Promise<TabitServerResponse<VerifyCodeResponse>>;
  checkout: (
    payload: CheckoutRequest
  ) => Promise<TabitServerResponse<CheckoutResponse>>;
  lookup: (
    payload: LookupRequest,
    sessionToken: string,
    checkoutId: string
  ) => Promise<TabitServerResponse<LookupResponse>>;
  apply: (
    payload: ApplyRequest,
    sessionToken: string,
    checkoutId: string
  ) => Promise<TabitServerResponse<ApplyResponse>>;
  getIndustryList: (
    payload: IndustryListRequest,
    sessionToken: string,
    checkoutId: string
  ) => Promise<TabitServerResponse<IndustryListResponse>>;
  getProvinceList: (
    payload: ProvinceListRequest,
    sessionToken: string,
    checkoutId: string
  ) => Promise<TabitServerResponse<ProvinceListResponse>>;
  getEntityList: (
    payload: EntityListRequest,
    sessionToken: string,
    checkoutId: string
  ) => Promise<TabitServerResponse<EntityListResponse>>;
  getAgreement: (
    payload: GetAgreementRequest,
    sessionToken: string,
    checkoutId: string
  ) => Promise<TabitServerResponse<GetAgreementResponse>>;
  signAgreement: (
    payload: SignAgreementRequest,
    sessionToken: string,
    checkoutId: string
  ) => Promise<TabitServerResponse<SignAgreementResponse>>;
  authorizeTransaction: (
    payload: AuthorizeTransactionRequest,
    sessionToken: string,
    checkoutId: string
  ) => Promise<TabitServerResponse<AuthorizeTransactionResponse>>;
};
