import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  DialogContent,
  Stepper,
  StepLabel,
  Step,
  Button,
  StepIconProps,
  styled,
  StepConnector,
  stepConnectorClasses,
  DialogActions,
  Dialog,
  DialogContentText,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
  CircularProgress,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faCheck,
  faHourglassHalf,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { flinksState } from "../../recoil/flinks/FlinksState";
import { approvalResponseState } from "../../recoil/approval-response/ApprovalResponseState";
import Flinks from "./Flinks";
import Accept from "./Accept";
import { useNavigate } from "react-router-dom";
import Declined from "./Declined";
import { mainDialogState } from "../../recoil/main-dialog/MainDialogState";
import { sessionInfoState } from "../../recoil/session-info/session-info-state";
import PendingApproval from "../pending-approval";
import { DataAPI } from "../../api/data";
import SnackbarAlert from "../../components/snackbar-alert";
import { dispatch } from "../../launcher/launcher";
import ManualReview from "./ManualReview";
import { captureException } from "@sentry/react";
import { track } from "../../utils";

const FlinksOptionsAccept = () => {
  const flinksStatus = useRecoilValue(flinksState);
  const closeDialog = useSetRecoilState(mainDialogState);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const approved = useRecoilValue(approvalResponseState);
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation("flinks");
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [errorMsg, setErrorMsg] = useState("");
  const [agreements, setAgreements] = useState([]);
  const sessionState = useRecoilValue(sessionInfoState);

  const ColorlibConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#4AB5D8",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#4AB5D8",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const handleClickOpen = () => {
    track("Institution not listed clicked");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const approvalResultRedirect = () => {
    switch (approved?.approvalStatus) {
      case 1:
        return <Accept setAgreements={setAgreements} />;
      case 2:
        return <ManualReview />;
      case -1:
        navigate("/personal-info");
        break;
      case -2:
      case 0:
        return <Declined />;
    }
  };

  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ ownerState }) => ({
    backgroundColor:
      activeStep === 2 &&
      approved?.approvalStatus !== 1 &&
      approved?.approvalStatus !== 2
        ? "#d32f2f"
        : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor:
        activeStep === 2 && approved?.approvalStatus !== 1
          ? approved?.approvalStatus !== 2
            ? "#d32f2f"
            : "#ccc"
          : "#4AB5D8",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#4AB5D8",
    }),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
    const icons: { [index: string]: React.ReactElement } = {
      1: <FontAwesomeIcon icon={faClipboard} size="lg" color="#13436D" />,
      2: <FontAwesomeIcon icon={faHourglassHalf} size="lg" color="#13436D" />,
      3: <FontAwesomeIcon icon={faCheck} size="lg" color="#13436D" />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Flinks", "Approve", "Accept"];

  const handleStep = async () => {
    if (approved?.approvalStatus === 1) {
      try {
        setLoading(true);
        await DataAPI.signAgreement(
          { signedAgreements: agreements },
          sessionState.sessionToken || "",
          sessionState.checkoutId || ""
        );
        track("Agreement signed");
        setLoading(false);
        navigate("/final");
      } catch (error) {
        captureException(error);
        setErrorMsg(t("errorGeneral"));
        setLoading(false);
      }
    } else {
      closeDialog(false);
      dispatch("tabit-rejected", {
        details: {
          sessionToken: sessionState.sessionToken,
          checkoutId: sessionState.checkoutId,
          redirectUrl: sessionState.redirectUrl,
        },
      });
    }
  };

  useEffect(() => {
    if (approved) {
      setActiveStep(2);
    }
    if (flinksStatus?.loginId.length && !!!approved) {
      let timer = setTimeout(() => setActiveStep(1), 3500);
      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approved, flinksStatus]);

  return (
    <>
      <DialogContent sx={{ pb: 0, pr: "27px" }}>
        <Stepper activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map((label) => {
            return (
              <Step key={label} sx={{ pr: 0 }}>
                <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 && <Flinks />}
        {activeStep === 1 && <PendingApproval />}
        {activeStep === 2 && approvalResultRedirect()}
      </DialogContent>
      <Typography
        variant={desktop ? "body1" : "body2"}
        sx={{
          color: "#4ab5d8",
          fontWeight: 600,
          cursor: "pointer",
          textAlign: "center",
          pt: "10px",
          display: flinksStatus?.loginId.length ? "none" : "block",
          "&:hover": {
            opacity: 0.7,
          },
        }}
        onClick={handleClickOpen}
      >
        {t("notListed")}
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant={desktop ? "body1" : "body2"}>
              {" "}
              {t("notListedDescription")}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("close")}</Button>
        </DialogActions>
      </Dialog>
      <DialogActions sx={{ p: "16px 24px" }}>
        <Box sx={{ position: "relative", width: "100%" }}>
          <Button
            onClick={async () => await handleStep()}
            variant="contained"
            disabled={loading}
            color="primary"
            sx={{
              color: "white",
              display: activeStep === 2 ? "block" : "none",
            }}
            fullWidth
          >
            {activeStep === 2 && approved?.approvalStatus === 1
              ? t("acceptAndComplete")
              : approved?.approvalStatus === 2
              ? t("close")
              : t("otherPaymentOptions")}
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translateY(-50%) translateX(-50%)",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </DialogActions>
      <SnackbarAlert errorMsg={errorMsg} />
    </>
  );
};

export default FlinksOptionsAccept;
