import { atom } from "recoil";
import { BusinessVerificationProps } from "./business-verification-props";

export const businessVerificationState = atom<BusinessVerificationProps | null>(
  {
    key: "businessVerificationState",
    default: {
      business: false,
    },
  }
);
