import { useState, useEffect, useMemo } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import {
  TextField,
  Box,
  Grid,
  Typography,
  Autocomplete,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FormInputProps } from "../form-input/form-input-props";
import { useTranslation } from "react-i18next";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { personalAdressState } from "../../recoil/personal-info/PersonalAddressState";
import { businessAdressState } from "../../recoil/business-info/BusinessAddressState";
import { personalGoogleAutocompleteState } from "../../recoil/personal-info/PersonalGoogleAutocompleteState";
import { businessGoogleAutocompleteState } from "../../recoil/business-info/BusinessGoogleAutocompleteState";
import { GoogleAutocompleteAddressProps } from "./google-autocomplete-props";
import { Loader } from "@googlemaps/js-api-loader";

const autocompleteService = { current: null };
const placeDetailsService = { current: null };

export default function GoogleMaps({
  error,
  helperText,
  addressType,
}: FormInputProps) {
  const setAddress = useSetRecoilState(
    addressType === "business" ? businessAdressState : personalAdressState
  );
  const [value, setValue] = useRecoilState(
    addressType === "business"
      ? businessGoogleAutocompleteState
      : personalGoogleAutocompleteState
  );
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<
    readonly GoogleAutocompleteAddressProps[]
  >([]);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const loader = new Loader({
    apiKey: "AIzaSyD5vjuwTUuokA-QtRMulQuJuSVRZPXxf4Y",
    libraries: ["places"],
  });
  loader.load();
  const { t } = useTranslation("personalInfo");

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: { input: string; componentRestrictions: object },
          callback: (
            results?: readonly GoogleAutocompleteAddressProps[]
          ) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        200
      ),
    []
  );

  const getProvinceAbbreviation = (provinceName: string) => {
    switch (provinceName) {
      case "Nova Scotia":
        return "NS";
      default:
        return provinceName;
    }
  };

  const getPostalCode = async (placeId: string | undefined) => {
    try {
      const postalRes: any = await new Promise((resolve, reject) => {
        if (!placeId) reject("placeId not provided");
        try {
          (placeDetailsService.current as any).getDetails(
            { placeId, fields: ["address_components"] },
            (details: any) => {
              return resolve(details?.address_components);
            }
          );
        } catch (e) {
          reject(e);
        }
      });
      const postalArr =
        postalRes?.filter((el: any) => el.types.includes("postal_code")) ||
        null;
      const postal = postalArr ? postalArr[0].long_name : "";
      return postal;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let active = true;
    if (
      !autocompleteService.current &&
      !placeDetailsService.current &&
      (window as any).google
    ) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
      const map = new (window as any).google.maps.Map(
        document.createElement("div")
      );
      placeDetailsService.current = new (
        window as any
      ).google.maps.places.PlacesService(map);
    }
    if (!autocompleteService.current || !placeDetailsService.current) {
      return undefined;
    }
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      { input: inputValue, componentRestrictions: { country: "ca" } },
      (results?: readonly GoogleAutocompleteAddressProps[]) => {
        if (active) {
          let newOptions: readonly GoogleAutocompleteAddressProps[] = [];
          if (value) {
            newOptions = [value];
          }
          if (results) {
            const filteredResults = results.filter(
              (d) =>
                d.types.includes("street_address") ||
                d.types.includes("premise")
            );
            newOptions = [...newOptions, ...filteredResults];
          }
          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      sx={{ my: 2 }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={async (
        _: any,
        newValue: GoogleAutocompleteAddressProps | null
      ) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        let street: string = "",
          city: string = "",
          province: string = "",
          country: string = "",
          postal: string = "";
        if (newValue) {
          if (newValue?.terms.length === 4) {
            street = newValue?.terms[0].value || "";
            city = newValue?.terms[1].value;
            province = getProvinceAbbreviation(newValue?.terms[2].value);
            country = newValue?.terms[3].value;
          } else {
            street = `${newValue?.terms[0].value} ${newValue?.terms[1].value}`;
            city = newValue?.terms[2].value;
            province = getProvinceAbbreviation(newValue?.terms[3].value);
            country = newValue?.terms[4].value;
          }
          postal = await getPostalCode(newValue?.place_id || "######");
          setAddress({ street, city, province, postal, country });
        } else {
          setAddress(null);
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          data-sl="mask"
          required
          size={desktop ? "medium" : "small"}
          label={t("addressLookup")}
          error={error}
          helperText={helperText}
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props} data-sl="mask">
            <Grid container alignItems="center">
              <Grid item>
                <Box sx={{ color: "text.secondary", mr: 2 }}>
                  <FontAwesomeIcon
                    icon={faMapLocationDot}
                    size="lg"
                    color="#4AB5D8"
                  />
                </Box>
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
