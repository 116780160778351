import { atom } from "recoil";
import { PersonalInfoProps } from "./personal-info-props";

export const personalInfoState = atom<PersonalInfoProps>({
  key: "personalInfoState",
  default: {
    first: "",
    last: "",
    dob: "",
    email: "",
    sin: "",
    terms: false,
    street: "",
    city: "",
    province: "",
    postal: "",
    country: "",
  },
});
