import { Route } from "./routes-props";
import { lazy } from "react";

const Welcome = lazy(() => import("../pages/welcome"));
const FlinksOptionsAccept = lazy(
  () => import("../pages/flinks-decline-or-accept")
);
const PhoneVerification = lazy(() => import("../pages/phone-verification"));
const CanBusinessVerification = lazy(
  () => import("../pages/business-verification")
);
const PersonalInfo = lazy(() => import("../pages/personal-info"));
const BusinessInfo = lazy(() => import("../pages/business-info"));
const SelectPaymentOption = lazy(() => import("../pages/display-options"));
const Final = lazy(() => import("../pages/final"));
const SmallCartTotal = lazy(() => import("../pages/small-cart-total"));

const routes: Route[] = [
  {
    path: "/",
    name: "Welcome Page",
    component: Welcome,
    exact: true,
  },
  {
    path: "/verify-business",
    name: "Canadian Business Verification",
    component: CanBusinessVerification,
    exact: true,
  },
  {
    path: "/verify-phone",
    name: "Phone Verification",
    component: PhoneVerification,
    exact: true,
  },
  {
    path: "/personal-info",
    name: "Personal Information",
    component: PersonalInfo,
    exact: true,
  },
  {
    path: "/business-info",
    name: "Business Information",
    component: BusinessInfo,
    exact: true,
  },
  {
    path: "/flinks-options-accept",
    name: "Flinks, Options, Accept",
    component: FlinksOptionsAccept,
    exact: true,
  },
  {
    path: "/final",
    name: "Final Step",
    component: Final,
    exact: true,
  },
  {
    path: "/display-options",
    name: "Select Payment Option",
    component: SelectPaymentOption,
    exact: true,
  },
  {
    path: "/small-cart-total",
    name: "Small Cart Total",
    component: SmallCartTotal,
    exact: true,
  },
];

export default routes;
