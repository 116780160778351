import { atom } from "recoil";
import { OptionType } from "../../components/autocomplete/autocomplete-props";

export const businessIndustryState = atom<OptionType | null>({
  key: "businessIndustryState",
  default: {
    industry: "",
    label: "",
    code: "",
    parentCode: "",
    keywords: [],
  },
});
