import { atom } from "recoil";
import { BusinessInfoProps } from "./business-info-props";

export const businessInfoState = atom<BusinessInfoProps>({
  key: "businessInfoState",
  default: {
    legal: "",
    businessAs: "",
    entity: "",
    industry: "",
    timeInBusiness: 0,
    street: "",
    city: "",
    province: "",
    postal: "",
    country: "",
  },
});
