import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { mainDialogState } from "../../recoil/main-dialog/MainDialogState";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ButtonRegular from "../../components/button";
import DialogRegular from "../../components/dialog";
import { MainProps } from "./main-props";
import SnackbarAlert from "../../components/snackbar-alert";
import { useQuery } from "../../hooks/query-hook";
import { TabitIcon } from "../../assets/icons/tabit-icon";
import { launcher, dispatch } from "../../launcher/launcher";
import { sessionInfoState } from "../../recoil/session-info/session-info-state";
import SmallCartTotal from "../small-cart-total";

const Main = ({ children }: MainProps) => {
  const [open, setOpen] = useRecoilState(mainDialogState);
  const [openWarning, setOpenWarning] = useState(false);
  const { t } = useTranslation("personalInfo");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const query = useQuery();
  const { sessionId, checkoutId, redirectUrl, isAmountQualified, cartTotal } =
    query;
  const [sessionState, setSessionState] = useRecoilState(sessionInfoState);

  useEffect(() => {
    if (sessionId && checkoutId && redirectUrl && cartTotal) {
      setSessionState({
        sessionToken: sessionId,
        checkoutId,
        redirectUrl,
        cartTotal: +cartTotal,
      });
    }
    if (
      (!sessionId || !checkoutId || !redirectUrl || !cartTotal) &&
      _tabit_config.mode !== "plugin"
    ) {
      setErrorMsg(t("errorGeneral"));
    }
  }, [checkoutId, sessionId, redirectUrl, cartTotal, setSessionState]);

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
    setOpenWarning(false);
    dispatch("tabit-abandoned", {
      details: {
        sessionToken: sessionState.sessionToken,
        checkoutId: sessionState.checkoutId,
      },
    });
  };

  const handleWarning = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpenWarning(!openWarning);
  };

  const handleClick = async () => {
    await launcher.validate(
      async () =>
        await launcher.run(
          () => {
            setOpen(true);
            setLoading(false);
            let session = launcher.getCheckoutSession();
            if (session == null) throw new Error("Unable to retrieve session!");
            setSessionState(session);
          },
          (error) => {
            error?.code?.startsWith("TC-1000")
              ? setErrorMsg(error.message)
              : setErrorMsg(t("errorGeneral"));
          },
          (isLoading) => {
            setLoading(isLoading);
          }
        ),
      (error) => {
        setErrorMsg(error.message);
      }
    );
  };

  const renderContent = () => {
    switch (_tabit_config.mode) {
      case "plugin":
        return (
          <Box>
            <Box sx={{ position: "relative" }}>
              <ButtonRegular
                startIcon={<TabitIcon width={21} height={30} />}
                onClick={handleClick}
                color="secondary"
                label="tabit direct"
                disabled={loading}
              />
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
            <DialogRegular onClose={handleWarning} open={open}>
              <Dialog
                open={openWarning}
                onClose={handleWarning}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
              >
                <DialogTitle id="alert-dialog-title">
                  {t("readyToLeave")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t("lossOfInfo")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                  <ButtonRegular
                    onClick={handleClose}
                    color="error"
                    label={t("agreeToLeave")}
                  />
                  <ButtonRegular onClick={handleWarning} label={t("cancel")} />
                </DialogActions>
              </Dialog>
              {sessionState.isAmountQualified ? (
                children
              ) : (
                <SmallCartTotal handleClose={handleClose} />
              )}
            </DialogRegular>
          </Box>
        );
      case "direct":
      default:
        return !sessionId || !checkoutId ? (
          <Box sx={{ textAlign: "center", py: 2 }}>{t("errorGeneral")}</Box>
        ) : (
          <Box>
            {isAmountQualified === "true" ? children : <SmallCartTotal />}
          </Box>
        );
    }
  };

  if (_tabit_config.useCustomRendering) {
    return <></>;
  } else {
    switch (_tabit_config.mode) {
      case "plugin":
        return (
          <Box display="flex" alignItems="left" justifyContent="left">
            <SnackbarAlert errorMsg={errorMsg} />
            {renderContent()}
          </Box>
        );
      case "direct":
      default:
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              height: `${window.innerHeight}px`,
              background:
                "linear-gradient(-10deg, #4AB5D8 25%,transparent 25.1%,transparent 75%,#4AB5D8 75.1%)",
            }}
          >
            <DialogRegular open={true}>
              <SnackbarAlert errorMsg={errorMsg} />
              {renderContent()}
            </DialogRegular>
          </Box>
        );
    }
  }
};

export default Main;
