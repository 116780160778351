import { atom } from "recoil";
import { SessionInfoProps } from "./session-info-props";

export const sessionInfoState = atom<SessionInfoProps>({
  key: "SessionInfoState",
  default: {
    sessionToken: "",
    checkoutId: "",
    redirectUrl: "",
    isAmountQualified: false,
    cartTotal: 0,
  },
});
