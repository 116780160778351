import { Dialog, DialogTitle, IconButton, Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { DialogProps } from "./dialog-props";
import logo from "../../assets/images/logo.png";

const DialogRegular = ({ children, open, onClose }: DialogProps) => {
  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <img src={logo} alt="Tabit Logo" height={40} />
        {onClose && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ padding: "6px 10.5px" }}
          >
            <FontAwesomeIcon icon={faXmark} color="#C4C4C4" />
          </IconButton>
        )}
      </DialogTitle>
      <Divider
        sx={{
          margin: "0px 24px",
          height: "1px",
          backgroundColor: "#C4C4C4",
        }}
      />
      {children}
    </Dialog>
  );
};

export default DialogRegular;
