import axios from "axios";
import { TabitServerResponse } from "./types/tabitTypes";

let apiEndpointBaseUrl: string | undefined = import.meta.env.VITE_BASE_URL;

export const MakePostRequest = async <Request, Response>(
  payload: Request,
  url: string,
  useCredentials: boolean,
  sessionToken?: string,
  checkoutId?: string
): Promise<TabitServerResponse<Response>> => {
  let headerObj: HeaderObj = {};
  let responseData: any;
  let responseStatus: any;
  let responseError: any;
  if (sessionToken !== undefined) headerObj.sessionToken = sessionToken;
  if (checkoutId !== undefined) headerObj.checkoutId = checkoutId;
  const sendRequest = async () => {
    try {
      var { data, status } = await axios.post<Response>(
        apiEndpointBaseUrl + url,
        payload,
        {
          withCredentials: useCredentials,
          headers: headerObj,
        }
      );
      responseData = data;
      responseStatus = status;
    } catch (error) {
      responseError = error;
    }
  };
  await sendRequest();
  return { data: responseData, error: responseError, status: responseStatus };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MakeGetRequest = async <Request, Response>(
  url: string,
  useCredentials: boolean,
  sessionToken?: string,
  checkoutId?: string
): Promise<TabitServerResponse<Response>> => {
  let headerObj: HeaderObj = {};
  let responseData: any;
  let responseStatus: any;
  let responseError: any;
  if (sessionToken !== undefined) headerObj.sessionToken = sessionToken;
  if (checkoutId !== undefined) headerObj.checkoutId = checkoutId;
  const sendRequest = async () => {
    try {
      var { data, status } = await axios.get<Response>(
        apiEndpointBaseUrl + url,
        {
          withCredentials: useCredentials,
          headers: headerObj,
        }
      );
      responseData = data;
      responseStatus = status;
    } catch (error) {
      responseError = error;
    }
  };
  await sendRequest();
  return { data: responseData, error: responseError, status: responseStatus };
};

type HeaderObj = {
  sessionToken?: string;
  checkoutId?: string;
};
