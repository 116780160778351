import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, DialogContent, Box, Fade } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import rocket from "../../assets/images/rocket.json";
import coffee from "../../assets/images/coffee.json";
import calculating from "../../assets/images/calculating.json";

const PendingApproval = () => {
  const { t } = useTranslation("optionsOrDeclined");
  const phrases = [t("makingCoffee"), t("sendingData"), t("finalizing")];
  const animations = [coffee, rocket, calculating];
  const [checked, setChecked] = useState(true);
  const [phraseData, setPhrase] = useState(phrases[0]);
  const [animationData, setAnimation] = useState(animations[0]);

  const fadeTime = 2500;

  useEffect(() => {
    let count = 0;
    setInterval(() => {
      setChecked((prev) => !prev);
    }, fadeTime);
    setInterval(() => {
      if (count === phrases.length - 1) {
        count = 0;
      } else count++;
      setPhrase(phrases[count]);
      setAnimation(animations[count]);
    }, fadeTime * 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DialogContent
      sx={{ textAlign: "center", height: { xs: "420px", md: "100%" } }}
    >
      <Player
        src={animationData}
        className="player"
        autoplay
        speed={phraseData === t("sendingData") ? 0.5 : 1.1}
        style={{ height: "300px", width: "100%" }}
      />
      <Box
        display="flex"
        sx={{
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          justifyContent: "center",
        }}
      >
        <Fade
          in={checked}
          timeout={{
            enter: fadeTime * 0.5,
            exit: fadeTime,
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 500, my: 3 }}
            color="primary"
          >
            {phraseData}
          </Typography>
        </Fade>
      </Box>
    </DialogContent>
  );
};

export default PendingApproval;
