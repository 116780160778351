import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { personalInfoState } from "../../recoil/personal-info/PersonalInfoFormState";
import { businessInfoState } from "../../recoil/business-info/BusinessInfoFormState";
import { AddressProps } from "./address-props";
import FormInput from "../../components/form-input";
import { Box, CircularProgress } from "@mui/material";
import SnackbarAlert from "../snackbar-alert";
import { useEffect, useState } from "react";
import { DataAPI } from "../../api/data";
import { sessionInfoState } from "../../recoil/session-info/session-info-state";
import { ProvinceListResponse } from "../../api/types/tabitTypes";
import { useQuery } from "../../hooks/query-hook";
import { captureException } from "@sentry/react";

type Props = {
  addressType: string;
};

const AddressInput = ({ addressType }: Props) => {
  const personal = useRecoilValue(personalInfoState);
  const business = useRecoilValue(businessInfoState);
  const { t } = useTranslation("personalInfo");
  const sessionState = useRecoilValue(sessionInfoState);
  const [provinceList, setProvinceList] = useState<ProvinceListResponse>({
    provinces: [],
  });
  const { lng } = useQuery();
  let isError: boolean = false;
  let isLoading: boolean = false;

  const getProvincesInformation = async () => {
    var result = await DataAPI.getProvinceList(
      { languge: lng || "en" },
      sessionState.sessionToken || "",
      sessionState.checkoutId || ""
    );
    if (result.error != null) {
      captureException(result.error);
      isError = true;
    }
    if (result.status === 200) {
      isError = false;
      isLoading = false;
      setProvinceList(result.data);
    } else {
      isError = true;
    }
  };

  useEffect(() => {
    isLoading = true;
    getProvincesInformation();
  }, []);

  const addressFields: {
    name: keyof AddressProps;
    label: string;
  }[] = [
    {
      name: "street",
      label: t("street"),
    },
    {
      name: "city",
      label: t("city"),
    },
    {
      name: "province",
      label: t("province"),
    },
    {
      name: "postal",
      label: t("postal"),
    },
    {
      name: "country",
      label: t("country"),
    },
  ];

  return isLoading || isError ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "325px",
      }}
    >
      <CircularProgress />
      {isError && <SnackbarAlert errorMsg={t("errorGeneral")} />}
    </Box>
  ) : (
    <>
      {addressFields.map((field) => (
        <FormInput
          options={
            field.name === "province"
              ? provinceList && provinceList.provinces
              : [{ name: "Canada", label: "Canada" }]
          }
          key={field.label}
          name={field.name}
          value={
            addressType === "business"
              ? business[field.name]
              : personal[field.name]
          }
          required
          fullWidth
          label={field.label}
          type="text"
          sx={{ my: 1 }}
        />
      ))}
    </>
  );
};

export default AddressInput;
