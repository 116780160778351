export const TabitIcon = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  return (
    <div style={{ width, height, marginTop: "2px" }}>
      <svg
        viewBox="0 0 50 80"
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
      >
        <g>
          <polygon
            id="svg_1"
            fill="#4ab5d8"
            points="24.179994612932205,15.510009825229645 24.179994612932205,0.3299868106842041 9.549987822771072,0.3299868106842041 9.549987822771072,15.510009825229645 0.20001274347305298,15.510009825229645 0.20001274347305298,26.39999395608902 37.38000491261482,26.39999395608902 37.38000491261482,15.510009825229645 24.179994612932205,15.510009825229645 "
          />
          <path
            id="svg_2"
            fill="#4ab5d8"
            d="m37.93,69.19c-2.09,1.65 -8.8,2.09 -11.66,2.09c-7.92,0 -16.72,-4 -16.72,-15.95l0,-28.93l14.63,5.71l0,22.14c0,3.3 2.09,4.73 5.83,4.73a44.58,44.58 0 0 0 6.82,-0.77l1.1,10.98z"
          />
        </g>
      </svg>
    </div>
  );
};
