import {
  Box,
  CircularProgress,
  Typography,
  useTheme,
  useMediaQuery,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { paymentOptionsState } from "../../recoil/payment-options/PaymentOptionsState";
import { selectedOptionState } from "../../recoil/selected-option/SelectedOptionState";
import { useState } from "react";
import OptionsAccordion from "../../components/options-accordion";
import SnackbarAlert from "../../components/snackbar-alert";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataAPI } from "../../api/data";
import { sessionInfoState } from "../../recoil/session-info/session-info-state";
import { captureException } from "@sentry/react";
import { track } from "../../utils";

const SelectPaymentOption = () => {
  const [loaded, setLoaded] = useState(false);
  const selectedOption = useRecoilValue(selectedOptionState);
  const options = useRecoilValue(paymentOptionsState);
  const [paymentOptionsAvailable, setPaymentOptionsAvailable] =
    useRecoilState(paymentOptionsState);
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation("optionsOrDeclined");
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const sessionState = useRecoilValue(sessionInfoState);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    (async () => {
      setLoaded(false);
      try {
        if (paymentOptionsAvailable.paymentOptions.length > 0) {
          setLoaded(true);
          return;
        }
        const { data } = await DataAPI.getPaymentOptions(
          {},
          sessionState.sessionToken || "",
          sessionState.checkoutId || ""
        );
        setLoaded(true);
        setPaymentOptionsAvailable(data);
      } catch (error) {
        captureException(error);
        setErrorMsg(t("errorRequest"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaymentOptionSelectionClickEvent = async () => {
    setLoaded(false);
    try {
      const index: number = parseInt(
        (selectedOption as string).replace(/[^0-9]/g, "")
      );
      const selectedOptionId: string =
        paymentOptionsAvailable.paymentOptions[index].id;
      await DataAPI.setPaymentOption(
        { paymentOptionId: selectedOptionId },
        sessionState.sessionToken || "",
        sessionState.checkoutId || ""
      );
      track("Offer selection completed");
      setLoaded(true);
      navigate("/personal-info");
    } catch (e) {
      setErrorMsg(t("errorRequest"));
    }
  };

  return loaded ? (
    <>
      <DialogContent sx={{ pb: 0, pr: "27px" }}>
        <Box>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: 600, marginTop: "10px", textAlign: "center" }}
          ></Typography>
          <Typography
            variant={desktop ? "body1" : "body2"}
            gutterBottom
            sx={{ marginTop: "10px", textAlign: "center" }}
          >
            {t("selectPaymentOption")}{" "}
            <Box
              component="span"
              sx={{
                color: "#4ab5d8",
                fontWeight: 600,
              }}
            >
              $
              {options.paymentOptions[0].cartTotal.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}
            </Box>
          </Typography>
          <OptionsAccordion options={options.paymentOptions} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: "10px 24px 16px 24px" }}>
        <Button
          onClick={handlePaymentOptionSelectionClickEvent}
          variant="contained"
          color="primary"
          sx={{ color: "white" }}
          type="submit"
        >
          {t("next")}
        </Button>
      </DialogActions>
    </>
  ) : (
    <DialogContent sx={{ pb: 0, pr: "27px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "325px",
        }}
      >
        <CircularProgress />
      </Box>
      <SnackbarAlert errorMsg={errorMsg} />
    </DialogContent>
  );
};

export default SelectPaymentOption;
