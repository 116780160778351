import {
  DialogContent,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass } from "@fortawesome/free-solid-svg-icons";
import { mainDialogState } from "../../recoil/main-dialog/MainDialogState";
import { sessionInfoState } from "../../recoil/session-info/session-info-state";
import { useSetRecoilState } from "recoil";
import { dispatch } from "../../launcher/launcher";
import { useRecoilValue } from "recoil";

const Final = () => {
  const closeDialog = useSetRecoilState(mainDialogState);
  const { t } = useTranslation("acceptFinal");
  const sessionState = useRecoilValue(sessionInfoState);

  setTimeout(() => {
    dispatch("tabit-approved", {
      details: {
        sessionToken: sessionState.sessionToken,
        checkoutId: sessionState.checkoutId,
        redirectUrl: sessionState.redirectUrl,
      },
    });
    closeDialog(false);
  }, 3000);

  return (
    <DialogContent sx={{ pt: "12px" }}>
      <Box
        sx={{
          minHeight: "325px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 600,
            }}
          >
            {t("thanks")}{" "}
            <Box component="span" sx={{ color: "#4AB5D8" }}>
              Tabit
            </Box>{" "}
            {t("today")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("wait")}
          </Typography>
          <Box
            sx={{
              m: 2,
              position: "relative",
              borderRadius: "50%",
            }}
          >
            <FontAwesomeIcon
              icon={faHourglass}
              spin
              size="2x"
              style={{
                padding: "34px 33px",
                color: "#4AB5D8",
              }}
            />
            <CircularProgress
              size={100}
              thickness={2}
              sx={{
                color: "#4AB5D8",
                position: "absolute",
                right: 0,
                left: 0,
                ml: "auto",
                mr: "auto",
                zIndex: 1,
              }}
            />
          </Box>
        </Box>
      </Box>
    </DialogContent>
  );
};

export default Final;
