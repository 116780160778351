import {
  DialogContent,
  Typography,
  Box,
  Avatar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import CanBusinessInput from "./CanBusinessInput";

const CanBusinessVerification = () => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation("businessVerification");

  return (
    <DialogContent>
      <Box display="flex" alignItems="center" marginBottom="10px">
        <Avatar sx={{ bgcolor: "#4AB5D8", marginRight: "10px" }}>
          <FontAwesomeIcon icon={faDollarSign} size="lg" color="#13436D" />
        </Avatar>
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          color="secondary"
          sx={{ fontWeight: 600, margin: 0 }}
        >
          {t("title")}
        </Typography>
      </Box>
      <Typography variant={desktop ? "body1" : "body2"} gutterBottom>
        {t("description")}
      </Typography>
      <Typography
        variant={desktop ? "body1" : "body2"}
        gutterBottom
        sx={{ fontWeight: 600, marginTop: "10px" }}
      >
        {t("note")}
      </Typography>
      <Box sx={{ textAlign: "center", marginTop: desktop ? "20px" : "10px" }}>
        <CanBusinessInput />
      </Box>
    </DialogContent>
  );
};

export default CanBusinessVerification;
