import { useEffect } from "react";
import { Box } from "@mui/material";
import { CountdownProps } from "./countdown-props";

const Countdown = ({ setTime, time }: CountdownProps) => {
  const tick = () => {
    if (time.minutes === 0 && time.seconds === 0) {
      reset();
    } else {
      if (time.seconds === 0 && time.minutes !== 0) {
        setTime({ minutes: time.minutes - 1, seconds: 59 });
      } else if (time.seconds !== 0) {
        setTime({ minutes: time.minutes, seconds: time.seconds - 1 });
      }
    }
  };
  const reset = () => setTime({ minutes: time.minutes, seconds: time.seconds });

  useEffect(() => {
    if (time.minutes >= 0 && time.seconds >= 0) {
      const timerId = setInterval(() => tick(), 1000);
      return () => clearInterval(timerId);
    }
  });

  return (
    <Box
      component="span"
      sx={{
        color: time.minutes === 0 && time.seconds === 0 ? "#F44336" : "#4ab5d8",
        fontWeight: 600,
      }}
    >
      {time.minutes === 0 && time.seconds === 0
        ? "EXPIRED"
        : `${time.minutes.toString().padStart(2, "0")}:${time.seconds
            .toString()
            .padStart(2, "0")}`}
    </Box>
  );
};

export default Countdown;
