import ReactDOM from "react-dom/client";
import "./i18n";
import App from "./App";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./assets/themes/theme";
import "./index.css";
import { launcher, dispatch } from "./launcher/launcher";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Smartlook from "smartlook-client";

if (import.meta.env.VITE_SMARTLOOK_PROJECT_KEY)
  Smartlook.init(import.meta.env.VITE_SMARTLOOK_PROJECT_KEY);
Smartlook.record({ forms: true, numbers: true, emails: false, ips: false });

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0, // we will lower this number in prod
  replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env.NODE_ENV,
  release: import.meta.env.VITE_VERSION,
});

const root = ReactDOM.createRoot(
  document.getElementById("tabit-direct") as HTMLElement
);

const setupLauncher = () => {
  window.tabit = launcher;
  dispatch("tabit-direct-ready");
};

setupLauncher();

root.render(
  <RecoilRoot>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </RecoilRoot>
);
