import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { flinksState } from "../../recoil/flinks/FlinksState";
import { personalInfoState } from "../../recoil/personal-info/PersonalInfoFormState";
import { businessInfoState } from "../../recoil/business-info/BusinessInfoFormState";
import { approvalResponseState } from "../../recoil/approval-response/ApprovalResponseState";
import { phoneVerificationState } from "../../recoil/phone-verification/PhoneVerificationFormState";
import { businessIndustryState } from "../../recoil/business-info/BusinessIndustryState";
import { Box, Typography, CircularProgress, Tooltip } from "@mui/material";
import IFrameLoader from "../../components/iframe-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faCheck,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { DataAPI } from "../../api/data";
import { sessionInfoState } from "../../recoil/session-info/session-info-state";
import SnackbarAlert from "../../components/snackbar-alert";
import { captureException } from "@sentry/react";
import { track } from "../../utils";

const Flinks = () => {
  const [input, setInput] = useRecoilState(flinksState);
  const personal = useRecoilValue(personalInfoState);
  const business = useRecoilValue(businessInfoState);
  const industry = useRecoilValue(businessIndustryState);
  const phoneNumber = useRecoilValue(phoneVerificationState);
  const setResponse = useSetRecoilState(approvalResponseState);
  const [errorMsg, setErrorMsg] = useState("");
  const [iconAnimationLoading, setIconAnimationLoading] = useState(true);
  const sessionState = useRecoilValue(sessionInfoState);
  const { t } = useTranslation("flinks");

  let flinksUrl = `${import.meta.env.VITE_FLINKS_URL}&language=${t(
    "language"
  )}`;

  useEffect(() => {
    if (input?.loginId) {
      callApprove(input?.loginId);
    }
    const setFromResult = (e: MessageEvent<any>) => {
      const { step } = e.data;
      if (step === "INSTITUTION_SELECTED") {
        track("Institution selected");
      }
      if (step === "SUBMIT_CREDENTIAL") {
        track("User logged in");
      }
      if (step === "SUBMIT_MFA") {
        track("User answered security question");
      }
      if (step === "REDIRECT") {
        setInput({
          loginId: e.data.loginId,
        });
        track("Bank information step completed");
        callApprove(e.data.loginId);
      }
    };
    window.addEventListener("message", setFromResult);
    return () => window.removeEventListener("message", setFromResult);
  }, []);

  const callApprove = async (flinksLoginId: string) => {
    try {
      const response = await DataAPI.apply(
        {
          flinksLoginId,
          buyerInformation: {
            firstName: personal.first,
            lastName: personal.last,
            dateOfBirth: personal.dob,
            emailAddress: personal.email,
            phoneNumber: phoneNumber?.phone.replace(/\D+/g, "").substring(1),
            sin: personal.sin,
            Address: {
              street: personal.street,
              city: personal.city,
              state: personal.province,
              postalCode: personal.postal,
              country: personal.country,
            },
          },
          businessInformation: {
            industry: industry?.parentCode,
            subIndustry: business.industry,
            timeInBusiness: business.timeInBusiness,
            tradeName: business.businessAs,
            legalBusinessName: business.legal,
            entityType: business.entity,
            Address: {
              street: business.street,
              city: business.city,
              state: business.province,
              postalCode: business.postal,
              country: business.country,
            },
          },
          cartInformation: {
            cartTotal: 1000,
            tax: 10,
            currency: "CAD",
          },
          shippingInformation: {
            carrierName: "FEDEX INC.",
            shippingAmount: 12,
          },
          itemInformation: [
            {
              name: "dsa",
              sku: "da",
              quantity: 1,
              imageURL: "",
              itemURL: "",
              tags: [],
              discountCode: "",
              discountAmount: 0,
              discountDisplayName: "",
            },
          ],
        },
        sessionState.sessionToken || "",
        sessionState.checkoutId || ""
      );
      setResponse({
        approvalStatus: response.data.approvalStatus,
        errors: response.data.errors,
      });
    } catch (error: any) {
      captureException(error);
      setErrorMsg(t("errorGeneral"));
    }
  };

  if (input?.loginId.length) {
    setTimeout(() => {
      setIconAnimationLoading(false);
    }, 1500);

    return (
      <Box
        sx={{
          mt: "20px",
          border: "solid 2px #4AB5D8",
          minHeight: "325px",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            color="primary"
            gutterBottom
            sx={{
              fontWeight: 600,
            }}
          >
            {t("connected")}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: 600,
            }}
          >
            {t("authenticated")}
          </Typography>
          <Box sx={{ m: 2, position: "relative", borderRadius: "50%" }}>
            <FontAwesomeIcon
              icon={iconAnimationLoading ? faLock : faCheck}
              size="2x"
              style={{
                padding: "31px 33px",
                borderRadius: iconAnimationLoading ? "0px" : "50%",
                border: iconAnimationLoading ? "none" : "2px solid #66bb6a",
                color: iconAnimationLoading ? "#4AB5D8" : "#66bb6a",
              }}
            />
            {iconAnimationLoading && (
              <CircularProgress
                size={100}
                thickness={2}
                sx={{
                  color: "#66bb6a",
                  position: "absolute",
                  right: 0,
                  left: 0,
                  ml: "auto",
                  mr: "auto",
                  zIndex: 1,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={{ mt: "20px", textAlign: "center" }}>
        <Typography variant="body2" gutterBottom>
          {t("pleaseConnect")}
        </Typography>
        <Tooltip
          title={
            <Box
              sx={{
                overflowY: "scroll",
                height: "300px",
                "::-webkit-scrollbar": { display: "none" },
              }}
            >
              <Typography variant="body2" my={1}>
                {t("whyConnect")}
              </Typography>
              {t("whyConnectDescription")}
              <Typography variant="body2" my={1}>
                {t("howSafe")}
              </Typography>
              {t("howSafeDescription")}
              <Typography variant="body2" my={1}>
                {t("whatHappens")}
              </Typography>
              {t("whatHappensDescription")}
            </Box>
          }
          enterTouchDelay={0}
          leaveTouchDelay={5000}
          arrow
        >
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              fontWeight: 600,
              mt: "5px",
              mb: "10px",
            }}
          >
            {t("whyConnect")}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{
                color: "#C4C4C4",
              }}
            />
          </Typography>
        </Tooltip>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 600,
            mb: "10px",
          }}
        >
          {t("emailNotificationNote")}
        </Typography>
        <IFrameLoader id="flinksIframe" src={flinksUrl} frameBorder="0" />
        <SnackbarAlert errorMsg={errorMsg} />
      </Box>
    );
  }
};

export default Flinks;
