import { DialogContent, Typography, Box, Slide } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme, useMediaQuery } from "@mui/material";
import ButtonRegular from "../../components/button";
import { useNavigate } from "react-router-dom";
import CONFIGURATION from "../../config/config";
import { track } from "../../utils";

const Welcome = () => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();
  const { t } = useTranslation("welcome");
  track("Landed on welcome page");

  const stepImages = {
    step1: `${CONFIGURATION.TABIT_STORAGE_URL}/images/welcome-step1.svg`,
    step2: `${CONFIGURATION.TABIT_STORAGE_URL}/images/welcome-step2.svg`,
    step3: `${CONFIGURATION.TABIT_STORAGE_URL}/images/welcome-step3.svg`,
  };

  const { step1, step2, step3 } = stepImages;

  const handleClick = () => {
    track("Get started clicked");
    navigate("/verify-business");
  };

  return (
    <DialogContent sx={{ pt: "12px", overflowX: "hidden" }}>
      <Typography
        variant={desktop ? "h5" : "h6"}
        component="div"
        gutterBottom
        color="secondary"
        sx={{ fontWeight: 600 }}
      >
        {t("title")}
      </Typography>
      <Typography variant={desktop ? "body1" : "body2"} gutterBottom>
        {t("description")}
      </Typography>
      <Slide in={true} timeout={600} direction="left">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginY={2}
          sx={{
            [theme.breakpoints.up("sm")]: {
              padding: "0px 48px",
            },
          }}
        >
          <Box sx={{ marginRight: "20px" }}>
            <Typography
              variant={desktop ? "h6" : "body1"}
              color="primary"
              sx={{ fontWeight: desktop ? 500 : 600 }}
            >
              {t("basicTitle")}
            </Typography>
            <Typography variant="body2">{t("basicDescription")}</Typography>
          </Box>
          <img
            src={step1}
            height={desktop ? 100 : 70}
            alt="Basic Info Icon"
          ></img>
        </Box>
      </Slide>
      <Slide in={true} timeout={600} direction="right">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginY={2}
          sx={{
            [theme.breakpoints.up("sm")]: {
              padding: "0px 48px",
            },
          }}
        >
          <img
            src={step2}
            height={desktop ? 100 : 70}
            alt="Payment Terms Icon"
            style={{ marginRight: "20px" }}
          ></img>
          <Box>
            <Typography
              variant={desktop ? "h6" : "body1"}
              color="primary"
              sx={{ fontWeight: desktop ? 500 : 600 }}
            >
              {t("paymentTitle")}
            </Typography>
            <Typography variant="body2">{t("paymentDescription")} </Typography>
          </Box>
        </Box>
      </Slide>
      <Slide in={true} timeout={600} direction="left">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginY={2}
          sx={{
            [theme.breakpoints.up("sm")]: {
              padding: "0px 48px",
            },
          }}
        >
          <Box sx={{ marginRight: "20px" }}>
            <Typography
              variant={desktop ? "h6" : "body1"}
              color="primary"
              sx={{ fontWeight: desktop ? 500 : 600 }}
            >
              {t("finalizeTitle")}
            </Typography>
            <Typography variant="body2">{t("finalizeDescription")}</Typography>
          </Box>
          <img
            src={step3}
            height={desktop ? 100 : 70}
            alt="Finalize Icon"
          ></img>
        </Box>
      </Slide>
      <Box sx={{ textAlign: "center", paddingTop: "10px" }}>
        <ButtonRegular label={t("buttonGetStarted")} onClick={handleClick} />
      </Box>
    </DialogContent>
  );
};

export default Welcome;
