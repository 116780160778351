import { Alert, AlertProps, Snackbar } from "@mui/material";
import { useState, forwardRef, useEffect } from "react";
import { SnackbarAlertProps } from "./snackbar-alert-props";

const CustomAlert = forwardRef<HTMLDivElement, AlertProps>(function CustomAlert(
  props,
  ref
) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarAlert = ({ errorMsg }: SnackbarAlertProps) => {
  const [showAlert, setShowAlert] = useState("");

  useEffect(() => {
    setShowAlert(errorMsg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMsg]);

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert("");
  };

  return (
    <Snackbar
      open={!!showAlert.length}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <CustomAlert
        onClose={handleClose}
        severity="error"
        sx={{ width: "100%" }}
      >
        {showAlert}
      </CustomAlert>
    </Snackbar>
  );
};

export default SnackbarAlert;
