import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { selectedOptionState } from "../../recoil/selected-option/SelectedOptionState";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  Chip,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const OptionsAccordion = ({ options }: any) => {
  const [expanded, setExpanded] = useRecoilState(selectedOptionState);
  const theme = useTheme();
  const { t } = useTranslation("optionsOrDeclined");
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      if (panel !== expanded) {
        setExpanded(newExpanded ? panel : false);
      }
    };

  return (
    <Box sx={{ pt: 1 }}>
      {options.map((option: any, index: number) => (
        <Accordion
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          key={option.name}
          sx={{
            border:
              expanded === `panel${index}`
                ? "2px solid #4Ab5d8"
                : "2px solid lightgray",
            boxShadow: 0,
            mb: expanded === `panel${index}` ? 2 : 1,
            borderRadius: "10px !important",
            "::before": {
              height: 0,
            },
          }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ px: desktop ? "16px" : "6px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontWeight: 700,
                  width: "100%",
                  fontSize: desktop ? "1rem" : "0.875rem",
                }}
              >
                {option.buyerFee === 0
                  ? option.months === 1 ? `${option.months} ${t("month")} ${t("zeroPercent")}` : `${option.months} ${t("months")} ${t("zeroPercent")}`
                  : `${option.months} ${t("months")}`}
                <Typography variant={desktop ? "body1" : "body2"}>
                  {option.installments}
                  {t("installments")}
                  {option.months}{" "}
                  {option.months === 1 ? t("month") : t("months")}
                </Typography>
              </Box>
              <Chip
                label={
                  <Typography variant={desktop ? "body1" : "body2"}>
                    $
                    {(
                      option.paymentAmount / option.installments
                    ).toLocaleString("en-US", { maximumFractionDigits: 0 })}
                    /{t("week")}
                  </Typography>
                }
                variant="outlined"
                color="primary"
                sx={{ fontWeight: 600, borderWidth: "2px" }}
                size={desktop ? "medium" : "small"}
              />
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{ p: desktop ? "0px 16px 16px 16px" : "0px 6px 10px 6px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography variant={desktop ? "body1" : "body2"}>
                {t("fee")}
              </Typography>
              <Typography
                variant={desktop ? "body1" : "body2"}
                sx={{ fontWeight: 600 }}
              >
                {option.buyerFee}%
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default OptionsAccordion;
