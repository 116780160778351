import {
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Avatar,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PersonalInfoInput from "./PersonalInfoInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const PersonalInfo = () => {
  const { t } = useTranslation("personalInfo");
  const navigate = useNavigate();

  const handlePersonalInfoBackBtnClick = () => {
    navigate("/display-options");
  };

  return (
    <>
      <DialogContent>
        <Box display="flex" alignItems="center" marginBottom="10px">
          <Avatar sx={{ bgcolor: "#4AB5D8", marginRight: "10px" }}>
            <FontAwesomeIcon icon={faUser} size="lg" color="#13436D" />
          </Avatar>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            color="secondary"
            sx={{ fontWeight: 600, margin: 0 }}
          >
            {t("titlePersonal")}
          </Typography>
        </Box>
        <PersonalInfoInput />
      </DialogContent>
      <DialogActions sx={{ p: "0px 24px 16px 24px" }}>
        <Button onClick={handlePersonalInfoBackBtnClick}>{t("back")}</Button>
        <Button
          form="submit-form"
          variant="contained"
          color="primary"
          sx={{ color: "white" }}
          type="submit"
        >
          {t("next")}
        </Button>
      </DialogActions>
    </>
  );
};

export default PersonalInfo;
