import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { personalInfoState } from "../../recoil/personal-info/PersonalInfoFormState";

const ManualReview = ({ firstName, isManualReviewPending }: { firstName?: string, isManualReviewPending?: boolean }) => {
  const { t } = useTranslation("optionsOrDeclined");
  const personal = useRecoilValue(personalInfoState);

  return (
    <Box>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: 600, marginTop: "10px" }}
      >
        {isManualReviewPending ? t("sorry") : t("almostThere")}{" "}
        <Box
          component="span"
          sx={{
            color: "#4ab5d8",
            fontWeight: 600,
          }}
        >
          {firstName || personal.first}.
        </Box>       
        <Typography variant="body1">
          {isManualReviewPending ? t("returningCustomerWithManualReview") : t("exceededTotal")}
          <br />
          <br />
          {t("hearFromUs")}         
        </Typography>
      </Typography>
    </Box>
  );
};

export default ManualReview;
