import { atom } from "recoil";
import { EntityType } from "../../components/autocomplete/autocomplete-props";

export const businessEntityState = atom<EntityType | null>({
  key: "businessEntityState",
  default: {
    label: "",
    code:""
  },
});
