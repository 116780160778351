import { useRecoilState } from "recoil";
import { TFunction, useTranslation } from "react-i18next";
import { timeInBusinessState } from "../../recoil/business-info/TimeInBusinessState";
import { NonLinearSliderProps } from "./non-linear-slider-props";
import {
  Slider,
  Box,
  Typography,
  FormControl,
  FormHelperText,
  useTheme,
  useMediaQuery,
} from "@mui/material";

function valueLabelFormat(value: number, t: TFunction) {
  const units = [t("months"), t("years")];

  let unitIndex = 0;
  let scaledValue = value;

  while (scaledValue >= 12 && unitIndex < units.length - 1) {
    unitIndex += 1;
    scaledValue -= 11;
  }

  return `${scaledValue > 20 ? "20+" : scaledValue} ${
    scaledValue === 1 ? units[unitIndex].replace("s", "") : units[unitIndex]
  }`;
}

function calculateValue(value: number) {
  return value;
}

const TimeBusinessSlider = ({ error, helperText }: NonLinearSliderProps) => {
  const [value, setValue] = useRecoilState(timeInBusinessState);
  const marks = [
    {
      value: 0,
      label: "0 mo.",
    },
    {
      value: 6,
      label: "6 mo.",
    },
    {
      value: 12,
      label: "1 yr.",
    },
    {
      value: 16,
      label: "5 yr.",
    },
    {
      value: 21,
      label: "10 yr.",
    },
    {
      value: 26,
      label: "15 yr.",
    },
    {
      value: 32,
      label: "20+ yr.",
    },
  ];
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation("businessInfo");

  const handleChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setValue(newValue);
    }
  };

  return (
    <FormControl fullWidth error={error}>
      <Box
        sx={{
          border: error ? "1px solid #d32f2f" : "1px solid #c4c4c4",
          borderRadius: "4px",
          boxSizing: "border-box",
          p: 1,
        }}
      >
        <Typography
          variant={desktop ? "body1" : "body2"}
          id="non-linear-slider"
          gutterBottom
          textAlign="center"
          sx={{ mb: 0 }}
        >
          {t("timeInBusinessLabel")}{" "}
          <Box
            component="span"
            fontWeight={600}
            sx={{ color: error ? "#d32f2f" : "#4AB5D8" }}
          >
            {valueLabelFormat(calculateValue(value), t)}
          </Box>
        </Typography>
        <Box sx={{ mx: 3 }}>
          <Slider
            value={value}
            min={0}
            step={1}
            max={32}
            sx={{ color: error ? "#d32f2f" : "#4AB5D8" }}
            marks={marks}
            scale={calculateValue}
            getAriaValueText={(value) => valueLabelFormat(value, t)}
            valueLabelFormat={(value) => valueLabelFormat(value, t)}
            onChange={handleChange}
            valueLabelDisplay="off"
            aria-labelledby="non-linear-slider"
          />
        </Box>
      </Box>
      <FormHelperText sx={{ color: "#d32f2f" }}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default TimeBusinessSlider;
