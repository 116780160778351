import Main from "./pages/main";
import { MemoryRouter as Router, Route, Routes } from "react-router-dom";
import routes from "./routes/routes";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./components/azure-app-insights";
import { Suspense } from "react";

const App = () => {
  return (
    <Main>
      <Router>
        <Suspense fallback={null}>
          <Routes>
            {routes.map((route) => (
              <Route
                path={route.path}
                key={route.path}
                element={<route.component />}
              />
            ))}
          </Routes>
        </Suspense>
      </Router>
    </Main>
  );
};

export default withAITracking(reactPlugin, App);
