import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import WELCOME_EN from "./locale/en/welcome.json";
import WELCOME_FR from "./locale/fr/welcome.json";
import BUSINESS_VERIFICATION_EN from "./locale/en/business-verification.json";
import BUSINESS_VERIFICATION_FR from "./locale/fr/business-verification.json";
import PHONE_VERIFICATION_EN from "./locale/en/phone-verification.json";
import PHONE_VERIFICATION_FR from "./locale/fr/phone-verification.json";
import PERSONAL_INFO_EN from "./locale/en/personal-info.json";
import PERSONAL_INFO_FR from "./locale/fr/personal-info.json";
import BUSINESS_INFO_EN from "./locale/en/business-info.json";
import BUSINESS_INFO_FR from "./locale/fr/business-info.json";
import FLINKS_EN from "./locale/en/flinks.json";
import FLINKS_FR from "./locale/fr/flinks.json";
import EMAIL_VERIFICATION_EN from "./locale/en/email-verification.json";
import EMAIL_VERIFICATION_FR from "./locale/fr/email-verification.json";
import OPTIONS_OR_DECLINED_EN from "./locale/en/options-or-declined.json";
import OPTIONS_OR_DECLINED_FR from "./locale/fr/options-or-declined.json";
import ACCEPT_FINAL_EN from "./locale/en/accept-final.json";
import ACCEPT_FINAL_FR from "./locale/fr/accept-final.json";
import SMALL_CART_EN from "./locale/en/small-cart.json";
import SMALL_CART_FR from "./locale/fr/small-cart.json";

const resources = {
  en: {
    welcome: WELCOME_EN,
    businessVerification: BUSINESS_VERIFICATION_EN,
    phoneVerification: PHONE_VERIFICATION_EN,
    personalInfo: PERSONAL_INFO_EN,
    businessInfo: BUSINESS_INFO_EN,
    flinks: FLINKS_EN,
    emailVerification: EMAIL_VERIFICATION_EN,
    optionsOrDeclined: OPTIONS_OR_DECLINED_EN,
    acceptFinal: ACCEPT_FINAL_EN,
    smallCart: SMALL_CART_EN,
  },
  fr: {
    welcome: WELCOME_FR,
    businessVerification: BUSINESS_VERIFICATION_FR,
    phoneVerification: PHONE_VERIFICATION_FR,
    personalInfo: PERSONAL_INFO_FR,
    businessInfo: BUSINESS_INFO_FR,
    flinks: FLINKS_FR,
    emailVerification: EMAIL_VERIFICATION_FR,
    optionsOrDeclined: OPTIONS_OR_DECLINED_FR,
    acceptFinal: ACCEPT_FINAL_FR,
    smallCart: SMALL_CART_FR,
  },
};

i18n
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
