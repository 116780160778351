import { useState } from "react";
import { IFrameLoaderProps } from "./iframe-loader-props";
import { CircularProgress, Box } from "@mui/material";

const IFrameLoader = (props: IFrameLoaderProps) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <Box>
      <Box
        sx={{
          display: loaded ? "none" : "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          border: "solid 2px #4AB5D8",
          minHeight: "325px",
          borderRadius: "4px",
        }}
      >
        <CircularProgress />
      </Box>
      <Box sx={{ display: loaded ? "block" : "none" }}>
        <iframe
          src={props.src}
          title={props.id}
          style={{
            width: "100%",
            height: "100%",
            border: "solid 2px #4AB5D8",
            minHeight: "325px",
            borderRadius: "4px",
          }}
          onLoad={() => {
            if (props.onFrameLoad) {
              props.onFrameLoad();
            }
            setTimeout(() => {
              setLoaded(true);
            }, 1500);
          }}
        ></iframe>
      </Box>
    </Box>
  );
};

export default IFrameLoader;
