import {
  Autocomplete,
  TextField,
  createFilterOptions,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { AutocompleteProps, OptionType } from "./autocomplete-props";
import { useRecoilState } from "recoil";
import { businessEntityState } from "../../recoil/business-info/BusinessEntityState";
import { businessIndustryState } from "../../recoil/business-info/BusinessIndustryState";

const AutocompleteSelect = ({
  label,
  options,
  categorized,
  error,
  helperText,
}: AutocompleteProps) => {
  const filterOptions = createFilterOptions({
    stringify: (option: OptionType) =>
      option.industry + option.label + option.keywords.join(""),
  });
  const [entity, setEntity] = useRecoilState(businessEntityState);
  const [industry, setIndustry] = useRecoilState(businessIndustryState);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const refinedOptions =
    categorized && options.length > 0
      ? options
          .map((industry) => {
            const optionArr = industry.subIndustries.map(
              (subIndustry: any) => ({
                industry: industry.label,
                label: subIndustry.label,
                code: subIndustry.code,
                parentCode: industry.code,
                keywords: subIndustry.keywords,
              })
            );
            return optionArr;
          })
          .flat()
      : [];

  return categorized ? (
    <Autocomplete
      disablePortal
      sx={{ mb: 2 }}
      options={refinedOptions}
      value={industry}
      filterOptions={filterOptions}
      onChange={(_, value) => setIndustry(value)}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      groupBy={(option) => option.industry}
      renderInput={(params) => (
        <TextField
          {...params}
          size={desktop ? "medium" : "small"}
          required
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
    />
  ) : (
    <Autocomplete
      disablePortal
      sx={{ mt: 1, mb: 2 }}
      options={options}
      value={entity}
      onChange={(_, value) => setEntity(value)}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      renderInput={(params) => (
        <TextField
          {...params}
          size={desktop ? "medium" : "small"}
          required
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default AutocompleteSelect;
