import { Button } from "@mui/material";
import { ButtonProps } from "./button-props";

const ButtonRegular = ({ label, onClick, disabled, color, startIcon }: ButtonProps) => {
  return (
    <Button
      startIcon={startIcon}
      variant="contained"
      color={color}
      sx={{ color: "white" }}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default ButtonRegular;
