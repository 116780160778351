import { Box, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass } from "@fortawesome/free-solid-svg-icons";
import { personalInfoState } from "../../recoil/personal-info/PersonalInfoFormState";
import { approvalResponseState } from "../../recoil/approval-response/ApprovalResponseState";

const Declined = () => {
  const { t } = useTranslation("optionsOrDeclined");
  const personal = useRecoilValue(personalInfoState);
  const approved = useRecoilValue(approvalResponseState);

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: 600, marginTop: "10px", textAlign: "center" }}
      >
        {t("sorry")}{" "}
        <Box
          component="span"
          sx={{
            color: "#4ab5d8",
            fontWeight: 600,
          }}
        >
          {personal.first} {personal.last},
        </Box>{" "}
        <br />
        {approved?.approvalStatus === 0 ? (
          t("unknownError")
        ) : (
          <>
            {t("notApproved")}{" "}
            <Box component="span" sx={{ color: "#4AB5D8" }}>
              Tabit
            </Box>{" "}
            {t("financing")}
          </>
        )}
      </Typography>
      {approved?.approvalStatus !== 0 ? (
        <>
          {" "}
          <FontAwesomeIcon icon={faHourglass} color="#4ab5d8" />
          <Typography component="span" variant="body1" sx={{ ml: 1 }}>
            {t("eligible")}
          </Typography>
        </>
      ) : null}
    </Box>
  );
};

export default Declined;
