import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const TermsConditions = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("personalInfo");
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography component="span" variant={desktop ? "body1" : "body2"}>
        {t("iAgreeTo")}{" "}
        <Box
          component="span"
          sx={{
            color: "#4ab5d8",
            fontWeight: 600,
            cursor: "pointer",
          }}
          onClick={handleClickOpen}
        >
          {t("esignDisclosure")}
        </Box>
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant={desktop ? "body1" : "body2"}>
              {" "}
              By agreeing to the following Terms and Conditions, the company and
              its owner(s) certify that all information and documents submitted
              in connection with this application are true, correct and
              complete. Additionally, the owner(s) authorize Merchant Growth
              Ltd. (doing business as Tabit) or any of its agents, partners, and
              affiliates to obtain and use business and non-business consumer
              credit reports from credit reporting agencies and any other
              information regarding the company and its owner(s) from third
              parties, both at the time of the initial funding application and
              at any time after the company has received funding as long as the
              company remains a client of Merchant Growth Ltd. (doing business
              as Tabit). At all times, Merchant Growth Ltd. (doing business as
              Tabit) will comply with the personal information collection,
              protection, use, sharing, and retention practices set out in the
              Privacy Policy, which can be reviewed here:{" "}
              <Link
                target="_blank"
                href="https://www.merchantgrowth.com/privacy-policy/"
              >
                Merchant Growth Ltd. (operating under the name Tabit)
              </Link>
              <br />
              <br />
              En acceptant les Conditions générales suivantes, le commerçant et
              son (ses) propriétaire(s) certifient que toutes les informations
              et tous les documents soumis dans le cadre de cette demande sont
              vrais, corrects et complets. De plus, le(s) propriétaire(s)
              autorise(nt) Merchant Growth Ltd. (faisant affaire sous le nom de
              Tabit) ou l'un de ses agents, partenaires et affiliés à obtenir et
              à utiliser des rapports de crédit à la consommation commerciaux et
              non commerciaux provenant d'agences d'évaluation du crédit et
              toute autre information relative au commerçant et à son ou ses
              propriétaires provenant de tiers, tant au moment de la demande de
              financement initiale qu'à tout moment après que le commerçant ait
              reçu le financement, tant que le commerçant demeure un client de
              Merchant Growth Ltd. (faisant affaire sous le nom de Tabit). En
              tout temps, Merchant Growth Ltd. (faisant affaire sous le nom de
              Tabit) se conformera aux pratiques de collecte, de protection,
              d'utilisation, de partage et de conservation des renseignements
              personnels énoncées dans la politique de confidentialité, qui peut
              être consultée ici:{" "}
              <Link
                target="_blank"
                href="https://www.merchantgrowth.com/privacy-policy/"
              >
                Merchant Growth Ltd. (faisant affaire sous lenom de Tabit)
              </Link>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("close")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TermsConditions;
