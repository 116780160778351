import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { phoneVerificationState } from "../../recoil/phone-verification/PhoneVerificationFormState";
import { personalInfoState } from "../../recoil/personal-info/PersonalInfoFormState";
import { businessAdressState } from "../../recoil/business-info/BusinessAddressState";
import { businessGoogleAutocompleteState } from "../../recoil/business-info/BusinessGoogleAutocompleteState";
import { businessInfoState } from "../../recoil/business-info/BusinessInfoFormState";
import { timeInBusinessState } from "../../recoil/business-info/TimeInBusinessState";
import {
  DialogContent,
  Typography,
  Box,
  Avatar,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint, faHourglass } from "@fortawesome/free-solid-svg-icons";
import ReactInputVerificationCode from "react-input-verification-code";
import PhoneInput from "./PhoneInput";
import Countdown from "../../components/countdown";
import SnackbarAlert from "../../components/snackbar-alert";
import { DataAPI } from "../../api/data";
import { sessionInfoState } from "../../recoil/session-info/session-info-state";
import { personalGoogleAutocompleteState } from "../../recoil/personal-info/PersonalGoogleAutocompleteState";
import { personalAdressState } from "../../recoil/personal-info/PersonalAddressState";
import { flinksState } from "../../recoil/flinks/FlinksState";
import { mainDialogState } from "../../recoil/main-dialog/MainDialogState";
import ManualReview from "../flinks-decline-or-accept/ManualReview";
import { captureException } from "@sentry/react";
import Smartlook from "smartlook-client";
import { track } from "../../utils";

const PhoneVerification = () => {
  const [activeStep, setActiveStep] = useState(0);
  const phone = useRecoilValue(phoneVerificationState);
  const [time, setTime] = useState({ minutes: 3, seconds: 0 });
  const [codeSentCounter, setCodeSentCounter] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [pendingName, setPendingName] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const setPersonal = useSetRecoilState(personalInfoState);
  const setFlinksState = useSetRecoilState(flinksState);
  const closeDialog = useSetRecoilState(mainDialogState);
  const setPersonalGoogleAutocomplete = useSetRecoilState(
    personalGoogleAutocompleteState
  );
  const setPersonalAddress = useSetRecoilState(personalAdressState);
  const setBusiness = useSetRecoilState(businessInfoState);
  const setBusinessGoogleAutocomplete = useSetRecoilState(
    businessGoogleAutocompleteState
  );
  const setTimeInBusiness = useSetRecoilState(timeInBusinessState);
  const setBusinesslAddress = useSetRecoilState(businessAdressState);
  const theme = useTheme();
  const { t } = useTranslation("phoneVerification");
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();
  const sessionState = useRecoilValue(sessionInfoState);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleTryAgain = async () => {
    setErrorMsg("");
    try {
      const response = await DataAPI.sendCode(
        {
          phoneNumber: phone?.phone.replace(/\D+/g, "").substring(1),
          countryCode: "1",
          requestType: "sms",
          emailAddress: undefined,
        },
        sessionState.sessionToken || "",
        sessionState.checkoutId || ""
      );
      if (response.data && response.data.isValid) {
        setCodeSentCounter(codeSentCounter + 1);
        setTime({ minutes: 3, seconds: 0 });
      } else {
        setErrorMsg(response.error.response.data.errors[0].message);
      }
    } catch (error) {
      captureException(error);
      setErrorMsg(t("errorRequest"));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setTime({ minutes: 3, seconds: 0 });
    setCodeSentCounter(0);
  };

  const handleLookup = async () => {
    setErrorMsg("");
    try {
      const { data } = await DataAPI.lookup(
        {
          phoneNumber: phone?.phone.replace(/\D+/g, "").substring(1),
        },
        sessionState.sessionToken || "",
        sessionState.checkoutId || ""
      );
      if (data.approvalAmount && data.approvalAmount < data.cartTotal) {
        setErrorMsg(
          "Cart total exceeding approved amount, please update your cart."
        );
        return true;
      }
      if (data.approvalStatus !== 2) {
        if (data.contactInformation) {
          const { firstName, lastName, birthDate, email, sin, mailingAddress } =
            data.contactInformation;
          setPersonal({
            first: firstName || "",
            last: lastName || "",
            dob:
              new Date(birthDate)
                .toLocaleString("en-US", { timeZone: "UTC" })
                .split(/\D/)
                .slice(0, 3)
                .map((num) => num.padStart(2, "0"))
                .join("/") || "",
            email: email || "",
            sin: sin || "",
            terms: false,
            street: mailingAddress.street || "",
            city: mailingAddress.city || "",
            province: mailingAddress.state || "",
            postal: mailingAddress.postalCode || "",
            country: mailingAddress.country || "",
          });
          setPersonalGoogleAutocomplete({
            description: `${mailingAddress.street}, ${mailingAddress.city}, ${mailingAddress.state}, ${mailingAddress.country}`,
            structured_formatting: {
              main_text: "",
              main_text_matched_substrings: [],
              secondary_text: "",
            },
          });
          setPersonalAddress({
            street: mailingAddress.street || "",
            city: mailingAddress.city || "",
            province: mailingAddress.state || "",
            postal: mailingAddress.postalCode || "",
            country: mailingAddress.country || "",
          });
        }
        if (data.accountInformation) {
          const { subIndustry, businessInformation } = data.accountInformation;
          setBusiness({
            legal: businessInformation.legalBusinessName || "",
            businessAs: businessInformation.accountName || "",
            entity: businessInformation.entityType || "",
            industry: subIndustry || "",
            timeInBusiness: businessInformation.timeInBusiness || 0,
            street: businessInformation.billingAddress.street || "",
            city: businessInformation.billingAddress.city || "",
            province: businessInformation.billingAddress.state || "",
            postal: businessInformation.billingAddress.postalCode || "",
            country: businessInformation.billingAddress.country || "",
          });
          setBusinessGoogleAutocomplete({
            description: `${businessInformation.billingAddress.street}, ${businessInformation.billingAddress.city}, ${businessInformation.billingAddress.state}, ${businessInformation.billingAddress.country}`,
            structured_formatting: {
              main_text: "",
              main_text_matched_substrings: [],
              secondary_text: "",
            },
          });
          setBusinesslAddress({
            street: businessInformation.billingAddress.street || "",
            city: businessInformation.billingAddress.city || "",
            province: businessInformation.billingAddress.state || "",
            postal: businessInformation.billingAddress.postalCode || "",
            country: businessInformation.billingAddress.country || "",
          });
          const timeToSave: number =
            businessInformation.timeInBusiness > 1
              ? businessInformation.timeInBusiness + 11
              : Math.round(businessInformation.timeInBusiness * 12);
          setTimeInBusiness(timeToSave);
          if (
            data.isFlinksLoginIdValid &&
            data.accountInformation.flinksLoginId
          ) {
            setFlinksState({
              loginId: data.accountInformation.flinksLoginId,
            });
          }
        }
      } else {
        setPendingName(data.contactInformation.firstName);     
        setLoading(false);     
        return true;
      }
    } catch (error: any) {
      captureException(error);
      setErrorMsg(t("errorRequest"));   
    }
  };

  const handleVerification = async (value: string) => {
    if (!isNaN(+value) && !(time.minutes === 0 && time.seconds === 0)) {
      setErrorMsg("");
      setLoading(true);
      setVerificationCode(value);
      try {
        const { data } = await DataAPI.verifyCode(
          {
            phoneNumber: phone?.phone.replace(/\D+/g, "").substring(1),
            countryCode: "1",
            verificationCode: value,
            requestType: "sms",
          },
          sessionState.sessionToken || "",
          sessionState.checkoutId || ""
        );
        if (data.isValid) {
          const isPending = await handleLookup();
          if (!isPending) {
            setLoading(false);
            Smartlook.identify(sessionState.sessionToken || "", {
              phoneNumber: phone?.phone.replace(/\D+/g, "").substring(1) || "",
            });
            track("Code is verified");
            navigate("/display-options");
          }
        } else {
          setErrorMsg(t("errorInvalidCode"));
          setVerificationCode("");
        }
      } catch (error: any) {
        captureException(error);
        setErrorMsg(t("errorInvalidCode"));
        setLoading(false);
      }
    }
  };

  return loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "325px",
      }}
    >
      <CircularProgress />
      <SnackbarAlert errorMsg={errorMsg} />
    </Box>
  ) : pendingName ? (
    <DialogContent>
      <Box marginBottom="10px">
        <ManualReview firstName={pendingName} isManualReviewPending={true}/>
        <Button
          onClick={() => closeDialog(false)}
          variant="contained"
          color="primary"
          sx={{
            color: "white",
            mt: 2,
          }}
          fullWidth
        >
          Close
        </Button>
      </Box>
    </DialogContent>
  ) : (
    <DialogContent>
      <Box display="flex" alignItems="center" marginBottom="10px">
        <Avatar sx={{ bgcolor: "#4AB5D8", marginRight: "10px" }}>
          <FontAwesomeIcon icon={faFingerprint} size="lg" color="#13436D" />
        </Avatar>
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          color="secondary"
          sx={{ fontWeight: 600, margin: 0 }}
        >
          {t("title")}
        </Typography>
      </Box>
      <Typography
        variant={desktop ? "body1" : "body2"}
        gutterBottom
        sx={{ fontWeight: 600, marginTop: "10px" }}
      >
        {t("description")}
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>{t("stepOneLabel")}</StepLabel>
          <StepContent>
            <Typography variant="body2">{t("stepOneDescription")}</Typography>
            <PhoneInput handleNext={handleNext} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t("stepTwoLabel")}</StepLabel>
          <StepContent>
            <Typography variant="body2">
              {t("stepTwoDescription")}{" "}
              <Box
                component="span"
                data-sl="mask"
                sx={{
                  color: "#4ab5d8",
                  fontWeight: 600,
                }}
              >
                *******{phone?.phone.slice(-4)}.
              </Box>
            </Typography>
            <Box
              className={`custom-styles ${
                (errorMsg === t("errorInvalidCode") &&
                  !isNaN(+verificationCode)) ||
                (time.minutes === 0 && time.seconds === 0)
                  ? "expired"
                  : ""
              }`}
            >
              <ReactInputVerificationCode
                autoFocus
                length={6}
                onChange={(value) => handleVerification(value)}
              />
            </Box>
            <Box textAlign="center">
              {codeSentCounter > 2 ? (
                <Typography
                  variant="body2"
                  sx={{
                    color: "#ACACAC",
                    fontWeight: 600,
                  }}
                >
                  {t("tryAgainTime")}{" "}
                </Typography>
              ) : (
                <Typography variant="body2">
                  {t("didntReceive")}{" "}
                  <Box
                    component="span"
                    sx={{
                      color: "#4ab5d8",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={handleTryAgain}
                  >
                    {t("tryAgain")}
                  </Box>
                </Typography>
              )}
              <Typography variant="body2">
                {t("wrongNumber")}{" "}
                <Box
                  component="span"
                  sx={{ color: "#4ab5d8", fontWeight: 600, cursor: "pointer" }}
                  onClick={handleBack}
                >
                  {t("edit")}
                </Box>
              </Typography>
              <FontAwesomeIcon
                icon={faHourglass}
                color="#4ab5d8"
                spin
                enableBackground="white"
              />
              <Typography component="span" variant="body2" sx={{ ml: 1 }}>
                {t("expiresIn")} <Countdown time={time} setTime={setTime} />{" "}
                {t("minutes")}
              </Typography>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
      <SnackbarAlert errorMsg={errorMsg} />
    </DialogContent>
  );
};

export default PhoneVerification;
