import { captureException, setUser } from "@sentry/react";
import { DataAPI } from "../api/data";
import { CheckoutRequest, CheckoutResponse } from "../api/types/tabitTypes";
import { track } from "../utils";
import CONFIGURATION from "../config/config";

export class Launcher {
  _tabit_checkout_obj: CheckoutRequest | null;
  _tabit_checkout_session: CheckoutResponse | null;
  constructor() {
    this._tabit_checkout_obj = null;
    this._tabit_checkout_session = null;
  }

  getCheckoutSession() {
    return {
      ...this._tabit_checkout_session,
      cartTotal: this._tabit_checkout_obj
        ? +this._tabit_checkout_obj.cartTotal
        : 0,
      redirectUrl: this._tabit_checkout_obj?.seller.redirectUrl,
    };
  }

  checkout(obj: CheckoutRequest) {
    this._tabit_checkout_obj = obj;
  }

  async validate(
    callbackSuccess?: () => void,
    callbackError?: (error: any) => void
  ) {
    try {
      if (!this._tabit_checkout_obj) {
        throw new Error("Please run checkout first!");
      }
      if (callbackSuccess) callbackSuccess();
    } catch (error) {
      if (callbackError) callbackError(error);
    }
  }

  async run(
    callbackSuccess?: () => void,
    callbackError?: (error: any) => void,
    callbackLoading?: (isLoading: boolean) => void
  ) {
    if (this._tabit_checkout_obj == null) {
      throw new Error("Please run checkout first!");
    }
    try {
      if (callbackLoading) callbackLoading(true);

      const { platform, seller } = this._tabit_checkout_obj;
      const response = await DataAPI.checkout(this._tabit_checkout_obj);
      if (response.error && response.error.response.data.errors.length)
        throw response.error.response.data.errors[0];
      const { sessionToken, checkoutId, isAmountQualified } = response.data;
      setUser({ SessionId: sessionToken, CheckoutId: checkoutId });

      if (platform.renderType === "standalone") {
        const encodedToken = encodeURIComponent(sessionToken);
        const encodedCartTotal = encodeURIComponent(
          this._tabit_checkout_obj.cartTotal
        );
        const encodedCheckoutId = encodeURIComponent(checkoutId);
        const encodedIsAmountQualified = encodeURIComponent(isAmountQualified);
        const encodedRedirectUrl = encodeURIComponent(seller.redirectUrl || "");
        window.open(
          `${CONFIGURATION.TABIT_DIRECT_URL}?sessionId=${encodedToken}&checkoutId=${encodedCheckoutId}&redirectUrl=${encodedRedirectUrl}&isAmountQualified=${encodedIsAmountQualified}&cartTotal=${encodedCartTotal}`
        );
        if (callbackLoading) callbackLoading(false);
        return;
      }

      this._tabit_checkout_session = response.data;

      if (callbackLoading) callbackLoading(false);
      if (callbackSuccess) callbackSuccess();
    } catch (error: any) {
      captureException(error);
      if (callbackError) callbackError(error);
    }
  }
}

export const dispatch = (
  event:
    | "tabit-direct-ready"
    | "tabit-approved"
    | "tabit-rejected"
    | "tabit-abandoned",
  details?: any
) => {
  const customEvent = new CustomEvent(event, { detail: details });
  window.dispatchEvent(customEvent);
  track(`Dispatched ${event} event notification.`);
  if (event === "tabit-approved" && details.details.redirectUrl) {
    window.location.replace(
      `${details.details.redirectUrl}?status=tabit-approved&checkoutId=${details.details.checkoutId}&sessionId=${details.details.sessionToken}`
    );
  }
  if (event === "tabit-rejected" && details.details.redirectUrl) {
    window.location.replace(
      `${details.details.redirectUrl}?status=tabit-rejected&checkoutId=${details.details.checkoutId}&sessionId=${details.details.sessionToken}`
    );
  }
};

export const launcher = new Launcher();
