import { atom } from "recoil";
import { GetPaymentOptionsResponse } from "../../api/types/tabitTypes";

export const paymentOptionsState = atom<GetPaymentOptionsResponse>({
  key: "paymentOptionsState",
  default: {
    "errors":[],
    "paymentOptions":[]
  },
});
