import {
  Box,
  Avatar,
  Typography,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { personalInfoState } from "../../recoil/personal-info/PersonalInfoFormState";
import { approvalResponseState } from "../../recoil/approval-response/ApprovalResponseState";
import { personalAdressState } from "../../recoil/personal-info/PersonalAddressState";
import { object, string, TypeOf, literal, preprocess } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import FormInput from "../../components/form-input";
import AddressInput from "../../components/address-input";
import TermsConditions from "./TermsConditions";
import { track } from "../../utils";

const PersonalInfoInput = () => {
  const navigate = useNavigate();
  const [personal, setPersonal] = useRecoilState(personalInfoState);
  // eslint-disable-next-line
  const approvalResponse = useRecoilValue(approvalResponseState);
  const setResponse = useSetRecoilState(approvalResponseState);
  const [addressError, setAddressError] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(personal?.terms);
  const address = useRecoilValue(personalAdressState);
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const [autocomplete, setAutocomplete] = useState(true);
  const { t } = useTranslation("personalInfo");
  const sinRequired =
    approvalResponse?.approvalStatus === -1 &&
    !!approvalResponse.errors.length &&
    approvalResponse.errors[0].code === "TC-200030";

  if (sinRequired) track("SIN number required");

  const validationSchema = object({
    first: string()
      .nonempty(t("errorFirstRequired"))
      .regex(/^[a-z ,.'-]+$/i, t("errorFirstInvalid"))
      .max(40, t("errorFirstLength")),
    last: string()
      .nonempty(t("errorLastRequired"))
      .regex(/^[a-z ,.'-]+$/i, t("errorLastInvalid"))
      .max(80, t("errorLastLength")),
    dob: preprocess(
      (arg) => {
        if (typeof arg == "string" || arg instanceof Date) {
          const today = new Date();
          const birthDate = new Date(arg);
          let age = today.getFullYear() - birthDate.getFullYear();
          const m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }
          return age >= 18 && age <= 80 ? arg : "00/00/0000";
        }
      },
      string()
        .nonempty(t("errorDobRequired"))
        .regex(
          /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
          t("errorDobInvalid")
        )
    ),
    email: string()
      .nonempty(t("errorEmailRequired"))
      .email(t("errorEmailInvalid"))
      .max(80, t("errorEmailLength")),
    sin: sinRequired
      ? string()
        .nonempty(t("errorSinRequired"))
        .length(11, t("errorSinInvalid"))
      : string().optional(),
    terms: literal(true),
    street: string().nonempty(t("errorStreetRequired")),
    city: string()
      .nonempty(t("errorCityRequired"))
      .max(40, t("errorCityLength")),
    province: string().nonempty(t("errorProvinceRequired")),
    postal: string()
      .nonempty(t("errorPostalRequired"))
      .regex(
        /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
        t("errorPostalInvalid")
      ),
    country: string().nonempty(t("errorCountryRequired")),
  });

  const methods = useForm<RegisterInput>({
    resolver: zodResolver(validationSchema),
  });

  const theme = useTheme();

  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  type RegisterInput = TypeOf<typeof validationSchema>;

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = methods;

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (address) {
      const { street, city, province, postal, country } = address;
      setValue("street", street);
      setValue("city", city);
      setValue("province", province);
      setValue("postal", postal);
      setValue("country", country);
      setAddressError(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, autocomplete]);

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    let error: boolean = false;
    if (autocomplete && !address) {
      setAddressError(true);
      error = true;
    } else {
      setAddressError(false);
    }
    if (!error) {
      setPersonal(values);
      track("Personal information step completed");
      if (sinRequired) {
        setResponse(null);
        navigate("/flinks-options-accept");
      } else {
        navigate("/business-info");
      }
    }
  };

  const addressHandler = () => {
    if (autocomplete && !address) {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
  };

  const handleClick = () => {
    track("E-sign disclosure checkbox checked");
    setTermsAccepted(!termsAccepted);
  };

  return (
    <FormProvider {...methods}>
      <Box
        id="submit-form"
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler, addressHandler)}
      >
        {sinRequired ? (
          <>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                fontWeight: 600,
                my: "10px",
              }}
            >
              {t("sinDescription")}
            </Typography>
            <FormInput
              name="sin"
              value={personal?.sin}
              required
              fullWidth
              label={t("sinLabel")}
              autoFocus={sinRequired}
              sx={{ my: 1 }}
            />
          </>
        ) : null}
        <FormInput
          name="first"
          value={personal?.first}
          required
          fullWidth
          label={t("firstNameLabel")}
          type="text"
          sx={{ my: 1 }}
          autoFocus={!sinRequired}
        />
        <FormInput
          name="last"
          value={personal?.last}
          required
          fullWidth
          label={t("lastNameLabel")}
          type="text"
          sx={{ my: 1 }}
        />
        <FormInput
          name="dob"
          placeholder="MM/DD/YYYY"
          value={personal?.dob}
          required
          fullWidth
          label={t("dobLabel")}
          type="text"
          sx={{ my: 1 }}
        />
        <FormInput
          name="email"
          value={personal?.email}
          required
          fullWidth
          label={t("emailLabel")}
          type="email"
          sx={{ my: 1 }}
        />
        <Box display="flex" alignItems="center" marginY="10px">
          <Avatar sx={{ bgcolor: "#4AB5D8", marginRight: "10px" }}>
            <FontAwesomeIcon icon={faLocationDot} size="lg" color="#13436D" />
          </Avatar>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            color="secondary"
            sx={{ fontWeight: 600, margin: 0 }}
          >
            {t("titleAddress")}
          </Typography>
        </Box>
        <Box sx={{ display: !autocomplete ? "none" : "block" }}>
          <FormInput
            name="address"
            error={addressError}
            helperText={addressError ? t("errorAddress") : ""}
          />
        </Box>
        <Box sx={{ display: autocomplete ? "none" : "block" }}>
          <AddressInput addressType="personal" />
          <div ref={scrollRef} />
        </Box>
        <Box textAlign="center" paddingBottom={1}>
          <b> Don't worry, providing this information will not affect your credit score!</b>
        </Box>
        <Box textAlign="center">
          <Typography variant={desktop ? "body1" : "body2"}>
            {autocomplete ? t("autocompleteOn") : t("autocompleteOff")}
            <Box
              component="span"
              sx={{ color: "#4ab5d8", fontWeight: 600, cursor: "pointer" }}
              onClick={() => setAutocomplete(!autocomplete)}
            >
              {t("clickHere")}
            </Box>
          </Typography>
        </Box>
        <FormGroup>
          <Box sx={{ textAlign: "center" }}>
            <FormControlLabel
              {...register("terms")}
              sx={{ mr: 0, pb: "3px" }}
              control={
                <Checkbox
                  required
                  checked={termsAccepted}
                  inputProps={{ "aria-label": t("termsAndConditions") }}
                  onClick={handleClick}
                />
              }
              label=""
            />
            <TermsConditions />
          </Box>
          <FormHelperText
            error={!!errors["terms"]}
            sx={{ textAlign: "center" }}
          >
            {errors["terms"] && !termsAccepted
              ? t("errorTermsAndConditions")
              : ""}
          </FormHelperText>
        </FormGroup>
      </Box>
    </FormProvider>
  );
};

export default PersonalInfoInput;
