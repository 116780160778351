import { forwardRef, useState } from "react";
import {
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
  IconButton,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { IMaskInput } from "react-imask";
import { Controller, useFormContext } from "react-hook-form";
import GoogleMaps from "../google-autocomplete";
import AutocompleteSelect from "../autocomplete";
import { FormInputProps } from "./form-input-props";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  onFocus: (event: any) => void;
  onBlur: (event: any) => void;
  name: string;
}

const TextMaskCustom = forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, _) {
    const [focused, setFocused] = useState(true);
    const { onChange, onFocus, onBlur, ...other } = props;

    let maskPattern: string = "";
    switch (props.name) {
      case "dob":
        maskPattern = "00/00/0000";
        break;
      case "sin":
        maskPattern = "000-000-000";
        break;
      case "phone":
        maskPattern = "+{1} (000) 000-0000";
        break;
    }
    return (
      <IMaskInput
        {...other}
        mask={maskPattern}
        thousandsSeparator=","
        onAccept={(value: any) => {
          onChange({ target: { name: props.name, value } });
        }}
        onFocus={(event) => {
          setFocused(false);
          onFocus(event);
        }}
        onBlur={(event) => {
          setFocused(true);
          onBlur(event);
        }}
        lazy={focused || props.name === "dob"}
        unmask="typed"
        overwrite
      />
    );
  }
);

const FormInput = ({ name, ...otherProps }: FormInputProps) => {
  const theme = useTheme();
  const [showSIN, setShowSIN] = useState(false);
  const [focused, setFocused] = useState(false);
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const handleClickShowSIN = () => {
    setShowSIN(!showSIN);
  };

  const handleMouseDownSIN = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={otherProps.value}
      render={({ field }) => {
        switch (name) {
          case "entity":
          case "industry":
            return (
              <AutocompleteSelect
                options={otherProps.options || []}
                error={otherProps.error}
                helperText={otherProps.helperText}
                label={otherProps.label || ""}
                categorized={otherProps.categorized || false}
              />
            );
          case "country":
          case "province":
            return (
              <FormControl
                size={desktop ? "medium" : "small"}
                sx={{ mt: 1, mb: "5px" }}
                fullWidth={otherProps.fullWidth}
                required={otherProps.required}
                error={!!errors[name]}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  {otherProps.label}
                </InputLabel>
                <Select
                  {...field}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Province"
                  data-sl="mask"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {otherProps.options &&
                    otherProps.options.map((option) => (
                      <MenuItem key={option.name} value={option.name}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {errors[name] ? errors[name].message : ""}
                </FormHelperText>
              </FormControl>
            );
          case "address":
            return (
              <GoogleMaps
                name="address"
                error={otherProps.error}
                helperText={otherProps.helperText}
                addressType={otherProps.addressType}
              />
            );
          case "phone":
            return (
              <TextField
                {...otherProps}
                {...field}
                data-sl="mask"
                size={desktop ? "medium" : "small"}
                error={!!errors[name]}
                helperText={errors[name] ? errors[name].message : ""}
                InputProps={{
                  inputComponent: TextMaskCustom as any,
                }}
                InputLabelProps={{
                  shrink: field.value ? true : false,
                }}
              />
            );
          case "dob":
            return (
              <TextField
                {...otherProps}
                {...field}
                data-sl="mask"
                size={desktop ? "medium" : "small"}
                error={!!errors[name]}
                helperText={errors[name] ? errors[name].message : ""}
                onFocus={() => {
                  setFocused(true);
                }}
                onBlur={() => {
                  setFocused(false);
                }}
                InputProps={{
                  inputComponent: TextMaskCustom as any,
                }}
                InputLabelProps={{
                  shrink: field.value || focused ? true : false,
                }}
              />
            );
          case "sin":
            return (
              <TextField
                {...otherProps}
                {...field}
                data-sl="mask"
                size={desktop ? "medium" : "small"}
                error={!!errors[name]}
                type={showSIN ? "text" : "password"}
                helperText={errors[name] ? errors[name].message : ""}
                InputLabelProps={{
                  shrink: field.value ? true : false,
                }}
                InputProps={{
                  inputComponent: TextMaskCustom as any,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowSIN}
                        onMouseDown={handleMouseDownSIN}
                        edge="end"
                      >
                        {showSIN ? (
                          <FontAwesomeIcon icon={faEyeSlash} size="sm" />
                        ) : (
                          <FontAwesomeIcon icon={faEye} size="sm" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            );
          case "email":
          case "last":
          case "first":
          case "legal":
          case "street":
          case "city":
          case "businessAs":
          case "postal":
            return (
              <TextField
                {...otherProps}
                {...field}
                data-sl="mask"
                size={desktop ? "medium" : "small"}
                error={!!errors[name]}
                helperText={errors[name] ? errors[name].message : ""}
              />
            );
          default:
            return (
              <TextField
                {...otherProps}
                {...field}
                size={desktop ? "medium" : "small"}
                error={!!errors[name]}
                helperText={errors[name] ? errors[name].message : ""}
              />
            );
        }
      }}
    />
  );
};

export default FormInput;
