import { useEffect, useState, useRef } from "react";
import {
  Box,
  Avatar,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { businessInfoState } from "../../recoil/business-info/BusinessInfoFormState";
import { businessAdressState } from "../../recoil/business-info/BusinessAddressState";
import { businessEntityState } from "../../recoil/business-info/BusinessEntityState";
import { businessIndustryState } from "../../recoil/business-info/BusinessIndustryState";
import { timeInBusinessState } from "../../recoil/business-info/TimeInBusinessState";
import { object, string, TypeOf, number } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import FormInput from "../../components/form-input";
import AddressInput from "../../components/address-input";
import TimeBusinessSlider from "../../components/non-linear-slider";
import {
  EntityListResponse,
  IndustryListResponse,
} from "../../api/types/tabitTypes";
import { sessionInfoState } from "../../recoil/session-info/session-info-state";
import { DataAPI } from "../../api/data";
import { useQuery } from "../../hooks/query-hook";
import { captureException } from "@sentry/react";
import { track } from "../../utils";

const BusinessInfoInput = () => {
  const navigate = useNavigate();
  const { lng } = useQuery();
  const [business, setBusiness] = useRecoilState(businessInfoState);
  const [addressError, setAddressError] = useState(false);
  const address = useRecoilValue(businessAdressState);
  const [entity, setEntity] = useRecoilState(businessEntityState);
  const [industry, setIndustry] = useRecoilState(businessIndustryState);
  const timeInBusiness = useRecoilValue(timeInBusinessState);
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const sessionState = useRecoilValue(sessionInfoState);
  const [industryData, setIndustryData] = useState<IndustryListResponse>({
    industries: [],
  });
  const [entityData, setEntityData] = useState<EntityListResponse>();
  const [isIndustryListLoaded, setIsIndustryListLoaded] =
    useState<boolean>(false);
  const [isEntityListLoaded, setIsEntityListLoaded] = useState<boolean>(false);

  const getIndustryList = async () => {
    const { data, status, error } = await DataAPI.getIndustryList(
      { languge: lng || "en" },
      sessionState.sessionToken || "",
      sessionState.checkoutId || ""
    );
    if (status === 200) {
      setIndustryData(data);
      setIsIndustryListLoaded(true);
    } else {
      captureException(error);
    }
    if (business.industry) {
      const selectedIndustry = data.industries.find((industry) =>
        industry.subIndustries.find((sub) => sub.code === business.industry)
      );
      const selectedSubIndustry = selectedIndustry?.subIndustries.find(
        (sub) => sub.code === business.industry
      );
      const industryToSave = {
        industry: selectedIndustry?.industry || "",
        label: selectedSubIndustry?.label || "",
        code: selectedSubIndustry?.code || "",
        parentCode: selectedIndustry?.code || "",
        keywords: selectedSubIndustry?.keyword || [],
      };
      if (selectedSubIndustry) setIndustry(industryToSave);
    }
  };

  const getEntityList = async () => {
    const { data, status, error } = await DataAPI.getEntityList(
      { languge: lng || "en" },
      sessionState.sessionToken || "",
      sessionState.checkoutId || ""
    );
    if (status === 200) {
      setEntityData(data);
      setIsEntityListLoaded(true);
    } else {
      captureException(error);
    }
    if (business.entity) {
      const selectedEntity = data.entityType.find(
        (entity) => entity.code === business.entity
      );
      if (selectedEntity) setEntity(selectedEntity);
    }
  };

  const [autocomplete, setAutocomplete] = useState(true);

  const { t } = useTranslation("businessInfo");

  const validationSchema = object({
    legal: string()
      .nonempty(t("errorLegalNameRequired"))
      .max(255, t("errorLegalNameLength")),
    entity: string(),
    industry: string(),
    timeInBusiness: number(),
    businessAs: string().nonempty(t("errorBusinessNameRequired")),
    street: string().nonempty(t("errorStreetRequired")),
    city: string()
      .nonempty(t("errorCityRequired"))
      .max(40, t("errorCityLength")),
    province: string().nonempty(t("errorProvinceRequired")),
    postal: string()
      .nonempty(t("errorPostalRequired"))
      .regex(
        /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
        t("errorPostalInvalid")
      ),
    country: string().nonempty(t("errorCountryRequired")),
  });

  const methods = useForm<RegisterInput>({
    resolver: zodResolver(validationSchema),
  });

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  type RegisterInput = TypeOf<typeof validationSchema>;

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = methods;

  useEffect(() => {
    getEntityList();
    getIndustryList();
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (entity?.label.length) {
      const { code } = entity;
      setValue("entity", code);
      clearErrors("entity");
    } else if (!entity?.label.length && business?.entity) {
      setError("entity", { type: "manual" });
    }

    if (industry?.label.length) {
      const { code } = industry;
      setValue("industry", code);
      clearErrors("industry");
    } else if (!industry?.label.length && business?.industry) {
      setError("industry", { type: "manual" });
    }

    if (timeInBusiness > 5) {
      const timeToSave: number =
        timeInBusiness > 11
          ? timeInBusiness - 11
          : Math.round((timeInBusiness / 12) * 1e2) / 1e2;
      setValue("timeInBusiness", timeToSave);
      clearErrors("timeInBusiness");
    } else if (
      timeInBusiness < 6 &&
      business?.timeInBusiness &&
      business?.timeInBusiness > 0.5
    ) {
      setError("timeInBusiness", { type: "manual" });
    }

    if (address) {
      const { street, city, province, postal, country } = address;
      setValue("street", street);
      setValue("city", city);
      setValue("province", province);
      setValue("postal", postal);
      setValue("country", country);
      setAddressError(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, entity, industry, timeInBusiness, autocomplete]);

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    let error: boolean = false;
    if (autocomplete && !address) {
      setAddressError(true);
      error = true;
    } else {
      setAddressError(false);
    }
    if (!entity?.label.length) {
      setError("entity", { type: "manual" });
      error = true;
    }
    if (!industry?.label.length) {
      setError("industry", { type: "manual" });
      error = true;
    } else {
      clearErrors("industry");
    }
    if (timeInBusiness < 6) {
      setError("timeInBusiness", { type: "manual" });
      error = true;
    } else {
      clearErrors("timeInBusiness");
    }
    if (!error) {
      track("Business information step completed");
      setBusiness(values);
      navigate("/flinks-options-accept");
    }
  };

  const addressHandler = () => {
    if (autocomplete && !address) {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
    if (!entity?.label.length) {
      setError("entity", { type: "manual" });
    }
    if (!industry?.label.length) {
      setError("industry", { type: "manual" });
    } else {
      clearErrors("industry");
    }
    if (timeInBusiness < 6) {
      setError("timeInBusiness", { type: "manual" });
    } else {
      clearErrors("timeInBusiness");
    }
  };

  return !isIndustryListLoaded || !isEntityListLoaded ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "325px",
      }}
    >
      <CircularProgress />
      {/* {(industryData.error || entityData.error) && (
        <SnackbarAlert errorMsg={t("errorGeneral")} />
      )} */}
    </Box>
  ) : (
    <FormProvider {...methods}>
      <Box
        id="submit-form"
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler, addressHandler)}
      >
        <FormInput
          name="legal"
          value={business?.legal}
          required
          fullWidth
          label={t("legalNameLabel")}
          type="text"
          sx={{ my: 1 }}
          autoFocus
        />
        <FormInput
          name="businessAs"
          value={business?.businessAs}
          required
          fullWidth
          label={t("businessNameLabel")}
          type="text"
          sx={{ my: 1 }}
        />
        <FormInput
          name="entity"
          options={entityData?.entityType}
          label={t("entityLabel")}
          error={!!errors["entity"]}
          helperText={errors["entity"] ? t("errorEntityRequired") : ""}
        />
        <FormInput
          name="industry"
          options={industryData.industries}
          label={t("industryLabel")}
          categorized
          error={!!errors["industry"]}
          helperText={errors["industry"] ? t("errorIndustryRequired") : ""}
          sx={{ mb: 1 }}
        />
        <TimeBusinessSlider
          error={!!errors["timeInBusiness"]}
          helperText={
            errors["timeInBusiness"] ? t("errorTimeInBusinessRequired") : ""
          }
        />
        <Box display="flex" alignItems="center" marginY="10px">
          <Avatar sx={{ bgcolor: "#4AB5D8", marginRight: "10px" }}>
            <FontAwesomeIcon icon={faLocationDot} size="lg" color="#13436D" />
          </Avatar>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            color="secondary"
            sx={{ fontWeight: 600, margin: 0 }}
          >
            {t("titleAddress")}
          </Typography>
        </Box>
        <Box sx={{ display: !autocomplete ? "none" : "block" }}>
          <FormInput
            name="address"
            error={addressError}
            helperText={addressError ? t("errorAddress") : ""}
            addressType="business"
          />
        </Box>
        <Box sx={{ display: autocomplete ? "none" : "block" }}>
          <AddressInput addressType="business" />
          <div ref={scrollRef} />
        </Box>
        <Box textAlign="center">
          <Typography variant={desktop ? "body1" : "body2"}>
            {autocomplete ? t("autocompleteOn") : t("autocompleteOff")}
            <Box
              component="span"
              sx={{ color: "#4ab5d8", fontWeight: 600, cursor: "pointer" }}
              onClick={() => setAutocomplete(!autocomplete)}
            >
              {t("clickHere")}
            </Box>
          </Typography>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default BusinessInfoInput;
