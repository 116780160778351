import { useEffect } from "react";
import {
  Box,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Button,
  Checkbox,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { businessVerificationState } from "../../recoil/business-verification/BusinessVerificationFormState";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { literal, object, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShop } from "@fortawesome/free-solid-svg-icons";
import { track } from "../../utils";

const validationSchema = object({
  business: literal(true),
});

type RegisterInput = TypeOf<typeof validationSchema>;

const CanBusinessInput = () => {
  const methods = useForm<RegisterInput>({
    resolver: zodResolver(validationSchema),
  });
  const [business, setBusiness] = useRecoilState(businessVerificationState);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    reset,
    handleSubmit,
    register,
    formState: { isSubmitSuccessful, errors },
  } = methods;
  const navigate = useNavigate();
  const { t } = useTranslation("businessVerification");

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful, business]);

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    setBusiness(values);
    track("Business over 6 months confirmed");
    navigate("/verify-phone");
  };

  const handleClick = () => {
    track("Confirm as Canadian Business checkbox checked");
    setBusiness({ business: !business?.business });
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: desktop ? "112px" : "82px",
              py: desktop ? "32px" : "24px",
              borderRadius: "50%",
              border: business?.business
                ? "2px solid #4AB5D8"
                : errors["business"]
                ? "2px solid #F44336"
                : "2px solid #C4C4C4",
              mb: "10px",
            }}
          >
            <FontAwesomeIcon
              icon={faShop}
              size={desktop ? "3x" : "2x"}
              color={business?.business ? "#13436D" : "#C4C4C4"}
            />
          </Box>
        </Box>
        <FormGroup sx={{ mb: 2 }}>
          <Box sx={{ textAlign: "center" }}>
            <FormControlLabel
              {...register("business")}
              control={
                <Checkbox
                  required
                  checked={business?.business}
                  inputProps={{ "aria-label": "Is a business confirmation" }}
                  onClick={handleClick}
                />
              }
              label={
                <Typography variant={desktop ? "body1" : "body2"}>
                  {t("confirm")}
                </Typography>
              }
            />
          </Box>
          <FormHelperText
            error={!!errors["business"]}
            sx={{ textAlign: "center" }}
          >
            {errors["business"] && !business?.business ? t("error") : ""}
          </FormHelperText>
        </FormGroup>
        <Button
          variant="contained"
          color="primary"
          sx={{ color: "white" }}
          type="submit"
        >
          {t("continue")}
        </Button>
      </Box>
    </FormProvider>
  );
};

export default CanBusinessInput;
