import { useTranslation } from "react-i18next";
import { track } from "../../utils";
import { dispatch } from "../../launcher/launcher";
import { sessionInfoState } from "../../recoil/session-info/session-info-state";
import { useRecoilValue } from "recoil";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SmallCartTotal = ({
  handleClose,
}: {
  handleClose?: (_: React.SyntheticEvent | Event, reason?: string) => void;
}) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation("smallCart");
  const sessionState = useRecoilValue(sessionInfoState);

  const handleClick = (event: React.SyntheticEvent | Event) => {
    if (handleClose) {
      handleClose(event);
    } else {
      track("Cart total is less than minimum amount");
      dispatch("tabit-rejected", {
        details: {
          sessionToken: sessionState.sessionToken,
          checkoutId: sessionState.checkoutId,
          redirectUrl: sessionState.redirectUrl,
        },
      });
    }
  };

  return (
    <DialogContent>
      <Box display="flex" alignItems="center" marginBottom="10px">
        <Avatar sx={{ bgcolor: "#4AB5D8", marginRight: "10px" }}>
          <FontAwesomeIcon icon={faDollarSign} size="lg" color="#13436D" />
        </Avatar>
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          color="secondary"
          sx={{ fontWeight: 600, margin: 0 }}
        >
          {t("title")}
        </Typography>
      </Box>
      <Typography variant={desktop ? "body1" : "body2"} gutterBottom>
        {t("description1")} <b>${(1000 - sessionState.cartTotal).toFixed(2)}</b>{" "}
        {t("description2")}
      </Typography>
      <Typography variant={desktop ? "body1" : "body2"} mt={2} gutterBottom>
        {t("description3")}
      </Typography>
      <Box sx={{ textAlign: "center", paddingTop: "10px" }}>
        <Button
          variant="contained"
          sx={{ color: "white" }}
          onClick={handleClick}
          fullWidth
        >
          {t("returnToCheckout")}
        </Button>
        <Typography variant={"caption"} mt={1}>
          {t("description4")}
        </Typography>
      </Box>
    </DialogContent>
  );
};

export default SmallCartTotal;
